@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.digitinputs {
  display: flex;
  justify-content: center;
  width: 100%;
}

.digitinputs_digit {
  height: 30px;
  margin: 5px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 8%;
  padding: 10px 0;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  font-size: 1em;
  text-align: center;
}

.error_digitinputs_digit {
  border: 1px solid red;
  animation: shake 0.3s ease-in-out;
}
