.StripeModal {
    z-index: 1;
    left: 0;
    width: 100%;
    height: 2vw;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--secondary-color);
    padding: 0.5vw;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 0.390625vw;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-out;
    transform: translateY(-100%);
    opacity: 0;
  }

  .StripeModal h2 {
    color: white !important;
  }
  
  .StripeModal-open {
    transform: translateY(0);
    opacity: 1;
  }
  
  .StripeModal h2 {
    font-size: 1.4em;
    margin: 0 15px;
    flex-grow: 1;
  }
  
  .StripeModal button {
    background-color: var(--secondary-color);
    border: none;
    border-radius: 40px;
    font-size: 1.5em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }