.or-occupancy-rate-card {
    width: 320px;
    padding: 20px;
    margin: 20px auto;
    border: 1px solid #ddd;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    text-align: center;
    height: 460px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.or-occupancy-rate-card h3 {
    font-size: 20px ;
    font-weight: 400 ;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}
.or-pie-chart-wrapper{

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
}

.occupancy-rate-card p{

    margin: 20px 10px;
    font-size: 20px;
    font-weight: normal;
    color: #444;
}

.or-custom-date-filter input {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: normal;
    color: #555;
}
.or-custom-date-filter label{
    font-size: 15px;
    font-weight: normal;
    color: #555;
}

.or-occupancy-rate-details {
    margin: 20px 10px;
    font-size: 15px;
    font-weight: normal;
    color: #444;
}

.or-gauge-wrapper {
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.or-gauge-labels {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin-top: 10px;
    font-size: 12px;
    font-weight: normal;
}

.or-icon {
    margin-right: 8px;
    color: #2c3e50;
    font-size: 14px;
}

/*.or-details-left p,
.or-details-left strong {
    font-size: 13px; 
    font-weight: normal;
}*/

.or-occupancy-rate-card:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}