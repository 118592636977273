.StripeModal {
  z-index: 2;
  left: 0;
  width: 100%;
  height: 2vw;
  margin-left: auto;
  margin-right: auto;
  background-color: #0D9813;
  color: white;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-out;
  transform: translateY(-100%);
  opacity: 0;
}

.StripeModal-open {
  transform: translateY(0);
  opacity: 1;
}

.StripeModal h2 {
  font-size: 1.4em;
  margin: 0 15px;
  flex-grow: 1;
}

.StripeModal button {
  background-color: #0D9813;
  border: none;
  border-radius: 40px;
  font-size: 1.5em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.StripeModal button:hover {
  background-color: #3da942;
}

.spinner {
  width: 3.2vw;
}

.container {
  width: 90%;
  height: 100%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.wijzer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 10px;
  align-items: center;
  width: 200px;
  border: 0.1px solid black;
  border-radius: 15px;
  padding: 5px;
  box-sizing: border-box;
  transition: all 0.3s ease;
  font-size: 0.75em;
  text-align: center;
  height: 8vh;
  padding-left: 1vh;  
}

.Mui {
  width: 5vh;
  height: 5vh;
  background-color: #fff;
  border: 2px solid black;
  border-radius: 2vh;
  display: flex;
  align-items: center;
  color: black !important; 
  justify-content: space-around;
}

.wijzer:hover {
  background-color: var(--secondary-color);
  color: white;
  transition: .3s;
}

.wijzer.active {
  background-color: var(--secondary-color);
  color: white;
}

.wijzer.active p {
  color: white;
}

.wijzer-grn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 10px;
  align-items: center;
  width: 200px;
  border: 0.5px solid black;
  border-radius: 15px;
  padding: 5px;
  box-sizing: border-box;
  transition: all 0.3s ease;
  font-size: 0.75em;
}

.wijzer-grn:hover {
  box-shadow: 4px 4px #003366;
  transition: .3s;
}

.stripe-icon {
  height: auto;
  width: 1.5vw;
  border-radius: 0.5em;
  border: 0.1em solid black;
  margin-left: -0.25em;
}

.stripe-icon-div {
  padding-left: 0.25rem;
}

.dashboardSection {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.boxText {
  border: 0.5px solid black;
  border-radius: 15px;
  padding: 5px;
}

.listing-info {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.listing-info .info-icon {
  max-width: 1.5em;
  max-height: 1.5em;
}

.enlist-info {
  padding: 2em;
}

.dashboard-card:hover {
  cursor: pointer;
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(30px) scale(0.5);
  }

  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

.boxColumns {
  height: auto;
  margin: 10px;
  min-width: 155px;
  display: flex;
  flex-direction: column;
}

.fullColumn {
  width: 80%;
}

.box {
  margin-right: 10px;
  margin-top: 20px;
  padding: 10px;
  width: 20vw;
}

.fullBox {
  width: auto;
  max-height: none;
}

.boxText {
  width: 80%;
  margin: auto;
  text-align: center;
}

#withdrawButton {
  background-color: #003366;
  color: #fff;
  margin: 10px;
  border-radius: 10px;
  box-shadow: none;
  padding: 10px;
  margin-left: auto;
}

.withdrawText {
  color: black;
}

.priceText {
  border: solid 1px black;
  border-radius: 15px;
  padding: 11px;
  width: 100px;
}

.withdrawContain {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10%;
  width: 50%;
  margin: 10px;
  text-align: left;
}

#date {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  color: #333;
  outline: none;
}

#date {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.review-content {
  text-align: start;
}

.selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.header {
  font-size: 1.75em;
}

.refresh-btn {
  padding: 1em;
  border-radius: 1.25em;
  border: none;
  background-color: #0D9813;
  color: white;
  margin-left: 85%;
}

.refresh-btn:hover {
  background-color: limegreen;
}

.visible {
  opacity: 1;
}

.button-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.undo-btn {
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  justify-content: space-between;
}

.undo-btn:hover {
  background-color: red;
  box-shadow: 4px 4px #003366;
}

.undo-btn .btn-text {
  color: white;
}

.returner {
  max-height: 5vh;
}

.save-btn {
  border-radius: 15px;
  width: 5vw;
}

.btn-text {
  font-size: 1rem;
}

.save-btn:hover {
  background-color: #0D9813;
  box-shadow: 4px 4px #003366;
}

.save-btn .btn-text {
  color: white;
}

.undo-txt {
  font-size: 15px;
}

.contentContainer-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
}

@media (max-width: 1024px) {
  .dashboard-1, .dashboard-2 {
    width: 100%;
    margin-bottom: 20px;
  }

  .dashboard-card {
    height: auto;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .welcome-msg {
    font-size: 1.5em;
    margin-left: 0;
  }

  .dashboardHost {
    display: flex;
    justify-content: center;
    gap: 2rem;
    width: 100%;
  }

  .dashboard-header {
    flex-direction: column;
    align-items: center;
  }

  .dashboardHost {
    flex-direction: column;
    align-items: center;
  }

  .dashboard-card {
    height: auto;
    width: 90%;
    margin: 0 auto 20px auto;
  }

  .accommodation-img {
    max-height: 20vh;
    width: 100%;
  }

  .accommodation-title {
    font-size: 1em;
  }

  .accommodation-location {
    font-size: 0.75em;
  }
}

@media (max-width: 480px) {
  .contentContainer-dashboard {
    gap: 1em;
  }

  .dashboard-header {
    margin: 0;
    padding: 0;
    flex-direction: column;
    align-items: center;
  }

  .dashboard-1 {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .dashboard-card {
    height: auto !important;
    width: 100% !important;
    margin: 10px 0 !important;
  }

  .accommodation-img {
    height: 30vh;
    width: 100%;
  }

  .accommodation-title {
    font-size: 0.9em;
  }

  .accommodation-location {
    font-size: 0.7em;
  }

  .personalInfoContent {
    width: 100%;
  }

  .infoBox {
    font-size: 0.9em;
  }
}

.StripeModal {
  z-index: 2;
  left: 0;
  width: 100%;
  height: auto;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-out;
  transform: translateY(-100%);
  opacity: 0;
}

.StripeModal-open {
  transform: translateY(0);
  opacity: 1;
}

.StripeModal h2 {
  font-size: 1.4em;
  margin: 0 15px;
  flex-grow: 1;
}

.StripeModal button {
  background-color: #0D9813;
  border: none;
  border-radius: 40px;
  font-size: 1.5em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.StripeModal button:hover {
  background-color: #3da942;
}

.spinner {
  width: 3.2vw;
}


.enlist-info {
  padding: 2em;
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(30px) scale(0.5);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

.boxColumns {
  height: auto;
  margin: 10px;
  min-width: 155px;
  display: flex;
  flex-direction: column;
}

.fullColumn {
  width: 80%;
}

.box {
  margin-right: 10px;
  margin-top: 20px;
  padding: 10px;
  width: 20vw;
}

.fullBox {
  width: auto;
  max-height: none;
}

.locationBox select {
  width: 100%;
  max-width: 100%;
  font-size: 1em;
  white-space: normal;
  overflow-wrap: break-word;
}

@media (max-width: 600px) {
  .locationBox {
    font-size: 0.9em;
  }

  .locationBox select {
    font-size: 0.9em;
  }
}

.boxText {
  width: 80%;
  margin: auto;
  text-align: center;
}

#withdrawButton {
  background-color: #003366;
  color: #fff;
  margin: 10px;
  border-radius: 10px;
  box-shadow: none;
  padding: 10px;
  margin-left: auto;
}

.withdrawText {
  color: black;
}

.priceText {
  border: solid 1px black;
  border-radius: 15px;
  padding: 11px;
  width: 100px;
}

.withdrawContain {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10%;
  width: 50%;
  margin: 10px;
  text-align: left;
}

#date {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  color: #333;
  outline: none;
}

#date:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.review-content {
  text-align: start;
}
.select-option {
  display: flex;
  text-align: center;
}

.header {
  font-size: 1.75em;
}

.personalInfoContent {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divider {
  height: 0.1vh;
  width: 70%;
  align-self: center;
  background-color: black;
}

.infoBox {
  text-align: center;
  width: 100%;
  background-color: #3da942;
  padding: 0.5em;
  border-radius: 0.25em;
}

#logoutButton {
  background-color: black;
  color: #fff;
  padding: 10px;
  border-radius: 40px;
  font-size: 1em;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 1em 0;
}

#logoutButton:hover {
  background-color: #3da942;
}
