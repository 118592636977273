.container {
    margin: auto;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: 400;
}

.calculatorBlock {
    border-radius: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 5px #003366;
    padding: 20px 15px;
    width: auto;
    margin: auto;
}
.iconsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    flex-wrap: wrap;
    height: 13vh;
    padding-left: 5%;
    padding-right: 5%;
}

.iconsContainerText {
    font-size: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    flex-wrap: wrap;
    width: 100%;
}

.iconTextGroup {
    display: flex;
    align-items: center;
    gap: 10px; 
}

.iconsContainerText img {
    width: 36px;
    object-fit: contain;
}

.iconsContainerText p {
    margin: 0;
}




.pages {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
}

.pageButtons {
    box-shadow: 0 0 5px #003366;
    border-radius: 5px;
    padding: 10px;
    width: 40px;
    height: 40px;
    background-color: white;
    border: none;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pageButtons:hover {
    background-color: #4c7cac;
    color: #fff;
    cursor: pointer;
}

.selected {
    background-color: #003366;
    color: #fff;
    box-shadow: none;
}

.cardHolder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1vw;
    margin-top: 2vw;
    margin-bottom: 1vw;
}

.card {
    height: 135px;
    width: 135px;
    max-height: 135px;
    max-width: 135px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 2px solid transparent;
}

.card img {
    max-width: 80px;
    max-height: 80px;
    width: auto;
    height: auto;
}

.card:hover, .selectedCard {
    border-radius: 10px;
    box-shadow: 0 0 5px #003366;
    cursor: pointer;
}

.selectedCard {
    border: 2px solid #003366;
    box-shadow: none !important;
}

.priceDisplay {
    max-width: 35%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #003366;
    box-shadow: 1px 1px 5px 1px #003366;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
}

.buttonHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.nextButtons {
    background-color: #003366;
    color: #fff;
    height: 45px;
    width: 150px;
    border-radius: 5px;
    box-shadow: none;
    padding: 10px;
    margin-top: 15px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

.headerTwoText{
    width: 100%;
}

.nextButtons:hover {
    background-color: #4c7cac;
    cursor: pointer;
    transition: background-color .3s ease-out;
}

.infoHolder {
    margin-top: 30px;
    margin-bottom: 30px;
}

.info {
    font-size: 1.2rem;
    margin: 8px 0;
    color: #333;
}

.infoHeader {
    color: green;
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.MainText {
    display: flex;
    flex-direction: column;
    padding-bottom: 4%;
    margin-left: 7.5%;
    width: 35%;
    margin-top: 10%;
    text-align: left;
}

.MainText h1 {
    font-size: 2.5rem;
    line-height: 1.2em;
    display: inline-block;
    white-space: nowrap;

}  button {
    align-self: start;
}

.MainText p{
    color: black;
    font-size: 1.5rem;
}

.textContainer {
    display: inline-block;
    position: relative;
    height: 40px;
    overflow: hidden;
    margin-left: 5px;
}

.textAnimated {
    position: relative;
    width: 100%;
    height: 100%;
    animation: slide 8s infinite;
}

.textAnimated span {
    display: block;
    font-size: 2.5rem;
    line-height: 42px;
    color: #003366;
}

@keyframes slide {
    0% { transform: translateY(0); }
    33.33% { transform: translateY(-100%); }
    66.66% { transform: translateY(-200%); }
    100% { transform: translateY(0); }
}

.MainText h1, .MainText p{
    text-align: left;
}
.highlightText{
    color: #003366;
}

.WhyHow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5%;
}
.easyHosting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5%;
    background-color: #f9f9f9;
}
.easyHosting_text{
    font-size: 25px;
}
.threeSteps{
    display: flex;
    justify-content: space-between;
    padding-bottom: 4%;
}
.steps{
    display: flex;
    flex-direction: column;
    width: 27%;

}
.steps h1{
    font-size: 100px;

}
.steps h2{
    font-weight: bold;
}
.steps h1, .steps h2, .steps p {
    margin-bottom: 0px;
    margin-top: 0px;
}
.whyHostText{
    width: 50%;
    margin-left: 10%;
    text-align: left;
    h1 {
        font-size: 2rem;
    }
    p {
        color: black;
    }
}
.whyHostText p{
    width: 85%;
}
.simpleSafe{
    background-color: #f9f9f9;
    padding: 5% 0;
}
.Cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1.7rem;
}

.RegisterBlock {
    display: flex;
    text-align: left;
    padding-top: 1%;
    background-color: #f9f9f9;
    justify-content: center;    
}
.firstSection{
    display: flex;
    justify-content: space-between;
}
.firstPicture{
    width: 50%;
}
.firstPicture img{
    border-bottom-left-radius: 80% 100%;
    border-top-left-radius: 80% 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.whyHost{
    display: flex;
    padding-top: 5%;
    padding-bottom: 5%;
}
.SecPicture{
    width: 45%;
    height: 60%;
}
.SecPicture img{
    width: 100%;
    height: 100%;
}



.simpleSafeCards{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2%;
    width: 90%;
    text-align: left;
}
.simpleSafeCards img{
    max-width:3vw;

}
.simpleSafeAll{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
        color: black;
    }
}
.SimpleSafeAllCards{
    display: flex;
    align-items: center;
    width: 75%;

}
.clientRevieuw {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
    margin: 2rem auto;
    width: 85%;
    gap: 1rem;


    h1 {
        font-size: 2.5rem;
        width: 100%;
        text-align: center;
        span {
            color: #003366;
        }
    }

    .client_text {
        border-style: solid;
        border-color: #003366;
        padding: 2rem;
        margin-bottom: auto;
        margin: 5px;
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-collapse: collapse;

        .client_content {
            display: flex;
            align-items: center;
            margin-top: 1rem;
        }

        img {
            border-radius: 50%;
            width: 70px;
            height: 70px;
            margin-right: 1rem;
        }

        .client_details {
            display: flex;
            flex-direction: column;
        }

        .highlightText {
            color: #003366;
            font-size: 5rem;
        }

        p {
            font-size: 1rem;
            color: #333;
            margin: 0.5rem 0;
        }

        h2 {
            align-self: flex-start;
            font-size: 1.5rem;
            font-weight: bold;
        }
    }
} 

.clientDetails {
    display: flex;
    align-items: center;
    justify-content: center;
}      

.clientReview img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.clientReviewMobile {
    width: 25vw;
    align-items: center;
    padding: 0px;
    text-align: center;
    border: 2px solid #003366;
    border-radius: 1px;
}

.clientDetails {
    display: flex;
    align-items: center;
    justify-content: center;
}      

.clientInfo {
    margin-left: 20px;
}

.clientReviewMobile img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

@media (max-width: 2560px) {
    .clientReviewMobile{
        visibility: hidden;
        height: 0;
    }
}

@media (max-width: 1024px) {
    .firstPicture{
        width: 39%;
    }
}
@media (max-width: 768px) {
    .clientRevieuw {
        display: none;
        .client_text {
            width: 100%;
            margin: 1rem 0;
        }
    }
    .clientReviewMobile{
        visibility: visible;
        height: 40vh;
    }
        h1 {
            font-size: 2rem;
        }
        .client_text {
            padding: 1.25rem;
            p {
                font-size: 1rem;
            }
            h2 {
                font-size: 1.2rem;
          }
    }

    .clientReview {
        width: 100%;
    }

    .firstPicture{
        width: 46%;
    }

    .clientReview .reviewSlide {
        flex-direction: column;
    }

    .reviewText{
        margin-bottom: 1rem;
    }

    .clientDetails {
        flex-direction: column;
    }

    .clientInfo {
        margin-left: 0;
        margin-top: 10px;
    }

    .clientReview img {
        width: 60px;
        height: 60px;
    }

    .clientReviewMobile {
        width: auto;
    }

    .clientReviewMobile .reviewSlide {
        flex-direction: column;
    }

    .clientDetails {
        flex-direction: column;
    }

    .clientInfo {
        margin-left: 0;
        margin-top: 10px;
    }

    .clientReview img {
        width: 60px;
        height: 60px;
    }
} 

@media (max-width: 320px) {
    .clientReviewMobile{
        visibility: visible;  
        height: 40vh;      
    }
    .clientRevieuw {
     display: none;
    }

    .client_text {
        padding: 1rem;
        p {
            font-size: 0.9rem;
        }
        h2 {
            font-size: 1rem;
        }
    }
}

.checkList {
    margin: 5% 0 5% 0;
    display: flex;
    flex-direction: column;
    h1 {
        font-size: 4rem;
        margin: 0;
        padding: 0;
    }
    .subText {
        font-size: 1.5rem;
        margin-top: 0;
    }
    .checkListItems {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin: 0 20% 0 20%;
        .checkListItem {
            display: flex;
            flex-direction: column;
            text-align: left;
            span {
                max-width: 15vw;
            }
        }
    }
}
.faq {
    .landing__faq {
        width: 50%;
        border: 2px solid #003366;
        border-radius: 10px;
        margin: 10px 0;
        padding: 15px;
        cursor: pointer;
        transition: max-height 0.4s ease;
        overflow: hidden;
        .landing__faq__body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            p {
                color: black;
            }
        }
        .landing__faq__question {
            font-weight: bold;
        }
        .landing__faq__answer {
            text-align: left;
        }
    }

    .faq__header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        img {
            max-width: 3vw;
            max-height: 3vh;
            align-self: center;
        }
        h1 {
            margin: 0;
        }
    }
    .faq__list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.personal__advice {
    display: flex;
    flex-direction: row;
    margin: 0 5%;
    justify-content: space-between;
    .personal__advice__left {
        margin: 10% 0;
        text-align: left;
        h1 {
            font-size: 3.5rem;
        }
        button {
            width: auto;
        }
    }
    img {
        width: auto;
        height: auto;
        object-fit: cover;
    }
}

.WhyHow_text {
    padding-bottom: 5%;
}

.updateGroupButtonDiv {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 4%;
    padding-top: 4%;
}

@media (max-width: 768px) {
    .container {
        width: auto;
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;
    }

    .MainText h1, .WhyHow_text h1 {
        font-size: 1.6rem;
    }


    .SecPicture{
        width: 45%;
        height: 60%;

    }

    .checkListItem__header{
        text-align: center;
    }
    
    .personal__advice {
        img {
            width: 50%;
            height: auto;
            object-fit: cover;
        }
    }
}

@media (max-width: 480px) {
    .container {
        width: auto;
    }

    .firstSection {
        display: flex;
        flex-direction: column-reverse;
        .MainText {
            width: 85vw;
            margin: 10px;
            padding: 5%;
            backdrop-filter: blur(0.25rem);
            border-radius: 1.5rem;
            background: rgba(0, 0, 0, 0.383);
            h1 {
                font-size: 1.5rem;
                margin: 0;
                padding: 0;
                color: white;
            }
            p {
                font-size: 1rem;
                color: white;

            }
            position: absolute;
            left: 47%;
            transform: translateX(-50%);
        }
        .firstPicture img {
            border-radius: 0;
            width: 100vw;
            object-fit: contain;
        }
    }
    .easyHosting {
        .easyHosting_text {
            h1 {
                font-size: 1.5rem;
            }
        }
        .threeSteps {
            flex-direction: column;
            .steps {
                width: auto;
                margin: 0 5%;
            }
        }
    }
    .whyHost {
        flex-direction: column;
        .SecPicture img {
            border-radius: 0;
            width: 100vw;
            object-fit: contain;
        }
        .whyHostText {
            h1 {
                width: 80vw;
            }

            p {
                width: 80vw;
            }
        }
    }
    .simpleSafe {
        .SimpleSafeAllCards {
            flex-direction: column;
            .simpleSafeCards {
                margin-bottom: 2%;
                img {
                    width: 10vw;
                    height: auto;
                    max-width: none;
                    align-self: start;
                }
            }
            .safeMiniText {
                h3 {
                    margin: 0;
                }
            }
        }
    }
    .checkList {
        h1 {
            font-size: 1.5rem;
        }
        .subText {
            font-size: 0.8rem;
        }
        .checkListItems {
            display: flex;
            flex-direction: column;
            margin: 0 5%;
            .checkListItem {
                width: 80vw;
                .checkListItem__text {
                    max-width: none;
                }
            }
        }
    }
    .faq {
        .faq__header {
            img {
                max-width: none;
                width: auto;
            }
            h1 {
                font-size: 1.5rem;
            }
        }
        .landing__faq {
            width: 80vw;
        }
    }
    .personal__advice {
        display: flex;
        flex-direction: column;
        .personal__advice__left {
            position: absolute;
            padding: 0.5rem;
            backdrop-filter: blur(0.25rem);
            background: rgba(0, 0, 0, 0.383);
            width: 80%;
            height: auto;
            left: 50%;
            transform: translateX(-50%);
            margin: 0;
            border-radius: 0 0 2rem 2rem;
            color: white;
            h1 {
                font-size: 1.75rem;
            }
            h3 {
                font-size: 0.75rem;
            }
        }
        img {
            width: auto;
            height: auto;
            object-fit: cover;
        }

    }
}
