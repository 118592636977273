.mc-dashboard-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    padding: 20px;
}

/* Monthly Comparison card */
.mc-comparison-card {
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto; 
}
.mc-comparison-card canvas { 
    max-width: 100%; 
    height: auto;
}

/* Header styling */
.mc-card-header {
    font-size: 1.2em;
    font-weight: normal;
    color: #333;
    margin-bottom: 10px;
}

/* Chart area */
.mc-chart-container {
    padding: 20px; 
    background-color: #f9f9f9; 
    border-radius: 10px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}
