/* General Card Styling */
.ro-revenue-card {
    padding: 5px; 
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ro-revenue-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
}

/* Title Styling */
.ro-revenue-title {
    font-size: 15px;
    margin-bottom: 10px;
    color: #333;
    font-weight: 300;
}


.ro-revenue-value {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #003366;
}

/* Grid for Revenue Overview Cards */
.ro-card-grid {
    display: Flex;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); 
    gap: 20px;
    justify-items: center;
    padding: 20px;
    width: 100%;
}


@media (max-width: 768px) {
    .ro-revenue-card {
        max-width: 100%;
    }
}