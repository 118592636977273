.confirm-email-header {
  text-align: center;
}

.confirm-email-text {
  text-align: center;
  width: 55%;
}

@media (max-width: 575.98px) {
  .confirm-email-text {
    width: 90%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .confirm-email-text {
    width: 70%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .confirm-email-text {
    width: 70%;
  }
}
