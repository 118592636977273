.PaymentOverview{
    display: flex;
    flex-direction: row;

}
.progress-bar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 20px 0;
}

.circle.completed {
    background-color: #4CAF50;
    color: white;
}


.line.completed {
    background-color: #4CAF50;
}


.number-complete{
    font-size: 16px;
    color: white;
}

.right-panel {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
