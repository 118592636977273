/* global styling */
html, body {
  height: 100%;
  margin: 0;
}

.headertext-container h1{
  font-size: 1.625em;
  font-weight: 800;
  color: #484848;
  margin: 0 auto;
}

.headertext-container p{
  font-size: 1em;
  color: #484848;
  margin: 0 auto;
}

.verification-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 50%;
  margin: auto;
  height: calc(100vh - 94.88px);
}

.verification-container svg{
  margin-bottom: 30px;
  cursor: pointer;
}

.back-btn{
  display: flex;
  align-items: center;
  cursor: pointer;
}

.back-btn p {
  margin: 0 0;
  color: black;
  text-decoration: underline;
}

.back-btn svg{
  margin: 0 5px 0 0;
}
/* global styling */

/* containers */
.main-container {
  display: flex;
  flex-wrap: wrap;
  margin: 30px auto;
  width: 85%;
  justify-content: space-between;
}

.left-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 50%;
}

.left-container h1 {
  font-size: 1.625em;
  font-weight: normal;
}

.right-container {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  width: 50%;
}

.bottom-container{
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
}
/* containers */

/* options */
.options-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.options-container:hover {
  transform: scale(1.05);
}

.options-container p{
  margin: 2px 0px;
}

hr {
  border: none;
  width: 98%;
  margin: 20px auto;
  border-top: 1px solid #e1e1e1;
}

.option-title{
  font-size: 0.875em;
  color: black;
}

.option-text{
  font-size: 0.875em;
  font-weight: 300;
  color: black;
}

.option-status-container {
  display: flex;
  gap: 5px;
}

.option-status {
  font-size: 0.75em;
  color: black;
}

.publish-btn {
  background-color: var(--secondary-color);
  color: white;
  border: 0px;
  padding: 7px 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.disabled-btn {
  background-color: #e1e1e1;
  color: white;
  border: 0px;
  padding: 7px 40px;
  border-radius: 5px;
  cursor: not-allowed;
  font-size: 1em;
}
/* options */

/* registration number input */
.registrationnumber-container {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 12px;
  gap: 24px;
}

.registrationnumber-inputfield h2{
  text-align: left !important;
  margin: 0 !important;
  font-size: 1em !important;
  font-weight: 500;
  color: #484848 !important;
  margin: 0 0 16px 0 !important;
}

.registrationnumber-inputfield input{
  width: 95%;
  padding: 10px 0 10px 10px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  font-size: 1em;
}

.registrationnumber-inputfield h1{
  font-size: 1.5em;
  font-weight: 800;
  color: #484848;
  margin: 0 0 16px;
}

.registrationnumber-inputfield p{
  font-weight: 400;
  color: #484848;
}

.registrationnumber-address h2{
  text-align: left !important;
  margin: 0 !important;
  font-size: 1em !important;
  font-weight: 500;
  color: #484848 !important;
  margin: 0 0 16px 0 !important;
}

.registrationnumber-address p{
  font-weight: 400;
  color: #484848;
}

.registrationnumber-legal{
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: flex-start;
}

.registrationnumber-legal p{
  font-size: 0.875em;
  color: #484848;
}
/* registration number input */

/* phone number inputfield */
.phonenumber-inputfield{
  margin: 40px 0;
  display: flex;
  flex-direction: column;
}

.phonenumber-inputfield input{
  font-size: 1em;
  padding: 10px 0 10px 5px;
  border: 1px solid #e1e1e1;
  border-top: none;
  border-radius: 0 0 10px 10px; 
}

.country-select-container {
  position: relative;
}

.country-select-container label {
  display: block;
  margin-bottom: 5px;
  color: #888;
  font-size: 0.9em;
}

.country-select-wrapper {
  position: relative;
  width: 100%;
}

.country-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: 1px solid #ddd;
  border-radius: 10px 10px 0 0;
  width: 100%;
  padding: 19px 40px 10px 5px;
  font-size: 1em;
  color: #484848;
  cursor: pointer;
  margin-bottom: 0;
}

.country-select:focus ~ .floating-label,
.country-select:not(:placeholder-shown) ~ .floating-label {
  top: 8px;
  left: 6px;
  font-size: 0.8em;
  color: #555;
}

.country-select-arrow {
  position: absolute;
  top: 90%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.country-select-arrow svg {
  width: 18px;
  height: 18px;
  stroke: #000;
}

.floating-label {
  position: absolute;
  top: 16px;
  left: 12px;
  font-size: 1em;
  color: #888;
  transition: 0.2s ease all;
  pointer-events: none;
  background-color: white; /* For background fill behind the label */
}

.country-select:focus ~ .floating-label,
.country-select:not([value=""]) ~ .floating-label {
  top: 8px;
  left: 6px;
  font-size: 0.8em;
  color: #555;
}
/* phone number inputfield */

/* code input */
.code-input-container{
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  align-items: center;
  gap: 25px;
}
.code-input-container p{
  font-size: 1em;
  color: #484848;
}

.code-input-container button{
  align-self: center !important;
  width: 15%;
  padding: 10px 0;
}
/* code input */
/* Extra small devices (phones, <576px) */
@media (max-width: 575.98px) {
  body{
    font-size: small;
  }

  .main-container{
    width: 100%;
  }

  .left-container, .right-container, .bottom-container{
    width: 90%;
    align-items: center;
    margin: 20px auto;
  }

  .publish-btn {
    font-size: 14px;
    padding: 14px 28px;
  }

  .verification-container {
    width: 90%;
    height: calc(100vh - 146.66px);
  }

  .registrationnumber-container svg{
    width: 15px;
    height: 20px;
  }
  
  .code-input-container button{
    width: 25%;
    padding: 14px 0;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .verification-container {
    width: 80%;
    height: calc(100vh - 151.76px);
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .verification-container {
    width: 70%;
    height: calc(100vh - 151.76px);
  }
  
  .code-input-container button{
    width: 25%;
    padding: 14px 0;
  }
}

/* Large devices (laptops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (desktops, 1200px and up) */
@media (min-width: 1200px) {
}
