.Search-bar {
  width: 42.5rem;
  margin: auto;
  box-shadow: 0 0 5px hsl(0 0% 78%);
  border-radius: 1.875rem;
  border: solid 1px #7171716a;
  display: flex;
  height: 3.43rem;
  box-sizing: border-box;
  background-color: white;
}

.css-h3fyes-control {
 text-align: center;
}

.Search-bar .Search-searchInputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.3125rem;
}

.Search-suggestions-container {
  position: relative;
  z-index: 1;
}

.css-qbdosj-Input {
  margin: 0 !important;
}

.autocomplete-container input::placeholder {
  text-align: center;
}
.searchTitle {
  color: var(--primary-color);
  font-size: 1rem;
  font-family: 'Kanit', sans-serif;
  font-weight: 500;
  text-overflow: ellipsis;
  transform: translateY(-15%);

}
.Search-check-in {
  width: 17%;
  cursor: auto;
}
.Search-location {
  width: 34%;
}

.input-calendar {
  background: none;
  border: none;
  text-align: center;
  width: 92%;
  height: 3.5rem;
}

.Search-bar>div {
  position: relative;
  transition: background 150ms ease, width 150ms ease;
}

.Search-bar>div::before {
  position: absolute;
  content: "";
  left: -1.85%;
  top: 50%;
  transform: translateY(-40%);
  width: 0.05rem;
  height: 60%;
  background: rgb(88, 153, 22);
}

.Search-bar>div:nth-of-type(1)::before {
  background: transparent;
}

.Search-bar>div:nth-of-type(3)::before {
  transform: translateY(-40%);
  left: -0.01%;
}

.Search-bar>div:nth-of-type(4)::before {
  background: solid;
  height: 56%;
  transform: translateY(-40%);
}

.DatePicker__calendarContainer {
  position: absolute;
  top: calc(-20% + 0.4rem);
  left: 60%;
  transform: translateX(-50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  border-radius: 0.5rem;
}

.searchbar-button {
  display: inline-block;
  outline: 0;
  cursor: pointer;
  padding: 3px;
  font-size: 0.6rem;
  line-height: 1.875rem;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  color: #ffffff;
  background-color: var(--primary-color);
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px 0px inset;
  height: 2.5rem;
  width: 2.5rem;
  margin-top: 0.45rem;
  margin-left: 0.4rem;
}

.searchbar-button:hover {
  opacity: 90%;
  transition-duration: 0.0s
}

.searchbar-button svg {
  color: rgb(255, 255, 255);
  margin-top: 0.625rem;
  margin-left: 0.2rem;
}

.search-text {
  font-size: 1rem;
  margin-right: 1rem;
}

.searchBar_inputfield {
  background: none;
  border: none;
  text-align: left;
  transform: translateY(10%);
  width: 90%;
  height: 2rem;
  margin-left: 1rem;
}
.searchBar_inputfield[type="search"]::-webkit-search-cancel-button {
  position: relative;
  color: black;
  font-size: large;
  filter: grayscale(100%);

}

.searchBar_inputfield:focus {
  outline: none;
}

.searchBar_inputfield::placeholder {
  color: var(--primary-color);
  font-family: 'Kanit', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  margin: auto;
}

.Search-button-section {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 1.625rem;
  border-radius: 1.25rem;
  width: 13.2%;
}

.Search-error-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 2px solid #f5c6cb;
  padding: 24px 36px;
  border-radius: 16px;
  font-size: 1.2rem;
  position: absolute;
  z-index: 1;
  height: auto;
  cursor: pointer;
  transform: translate(60%, 80%);
  max-width: 400px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.Search-guest-dropdown {
  display: none;
  position: absolute;
  top: calc(100% + 0.625rem);
  margin-right: -250%;
  transform: translateX(-50%) scale(0.95);
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  border-radius: 1.5625rem;
  padding: 1.5625rem;
  z-index: 999;
  width: 25.125rem;
  min-width: 15.625rem;
  box-sizing: border-box;
  cursor: auto;
}

.Search-guest-dropdown.active {
  display: block;
  opacity: 1;
  transform: translateX(-50%) scale(1);
}

@keyframes dropdownFadeIn {
  0% {
    opacity: 0;
    transform: translateX(-50%) scale(0.80);
  }

  100% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
}

.Search-guest-dropdown.active {
  animation: dropdownFadeIn 0.3s ease;
}

.Search-button-section.active .Search-guest-dropdown {
  display: block;
}

.Search-guestCounter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.40rem;
  border-bottom: 1px solid var(--primary-color);
}

.Search-guestCounter:last-child {
  border-bottom: none;
}

.Search-guestLabel {
  font-size: 1rem;
  color: var(--primary-color);
  text-align: left;
}

.Search-selected {
  color: green;
}

.Search-guestDescription {
  font-size: 0.7rem;
  color: #666;
  text-align: left;
}

.Search-guestCounter .Search-controls {
  display: flex;
  align-items: center;
  padding: 0 0.625rem;
}

.Search-guestCounter .Search-controls span {
  margin: 0 0.625rem;
  font-size: 1rem;
}

.Search-guestCounter .Search-controls button {
  border: 2px solid #cccccc;
  border-radius: 50%;
  background-color: #fff;
  padding: 0;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #000;
}

.Search-guestCounter .Search-controls button:hover {
  border-color: var(--primary-color);
}

/* test */
.Search-guestCounter .Search-controls button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.searchTitleGuest.hidden {
  display: none;
}

.searchTitleGuest {
  position: absolute;
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 500;
  padding-top: 0.3rem;
}
.Search-guestP {
  text-align: left;
  font-weight: 500;
  color: #000;
  font-size: 0.75rem;
  margin-top: 1rem;
}

.responsive-calendar {
  font-size: 11px !important;
}

.DatePicker__calendarArrow {
  display: none;
}

.Calendar__monthSelector.-open,
.Calendar__yearSelector.-open {
  transform: translateY(0);
}

@media (max-width: 768px) {
  .Search-guest-dropdown {
    width: calc(120% - 1.875rem);
    left: 50%;
    right: 50%;
    top: -275%;
  }
}

@media (max-width: 1000px) {
  .Search-bar {
    width: 95%;
  }
}

@media (min-width: 2500px) {
  .responsive-calendar {
    font-size: 1rem !important;
  }
}

@media (max-width: 1500px) {
  .responsive-calendar {
    font-size: 0.9rem !important;
  }
}

@media (max-width: 1200px) {
  .responsive-calendar {
    font-size: 0.7rem !important;
  }
}

@media (max-width: 768px) {
  .responsive-calendar {
    font-size: 0.7rem !important;
  }
}

@media (max-width: 1500px) {

  .Calendar__monthSelectorItemText,
  .Calendar__yearSelectorText {
    font-size: 8px !important;
  }
}

@media (max-width: 1200px) {

  .Calendar__monthSelectorItemText,
  .Calendar__yearSelectorText {
    font-size: 7px !important;
  }
}

@media (max-width: 768px) {

  .Calendar__monthSelectorItemText,
  .Calendar__yearSelectorText {
    font-size: 1rem !important;
  }
}

@media (min-width: 2500px) {

  .Calendar__monthSelectorItemText,
  .Calendar__yearSelectorText {
    font-size: 12px !important;
  }
}

/* dit is de styling voor de calendar gedeelte */
.DatePicker {
  position: relative;
  display: inline-block;
  z-index: 100;
  height: 0.1%;
}

.DatePicker__calendarContainer.-top{
  height: 4vh;
}

.DatePicker__input {
  position: relative;
  background: transparent;
  font-size: 12px;
  border: 1px solid transparent;
  outline: none;
  width: 80%;
  text-align: center;
  transform: translateY(-3.77rem);
  padding-bottom: 1rem;
  color: transparent;
  cursor: pointer;
  height: 2.3rem;
}

.DatePicker__input::selection {
  background: transparent;
}

.DatePicker__input::placeholder {
  color: transparent;
}

.Calendar__monthYear>* {
  font-size: 1.4em;
  color: black;
}

.Calendar__yearSelectorText {
  border: none;
  font-size: 1em;
  min-width: 85%;
  padding: 0.2em 0.5em;
  border-radius: 8.5px;
}

.Calendar__monthSelectorItemText {
  font-size: 0.8em;
}

.Calendar__sectionWrapper {
  position: relative;
  min-height: 25.4em;
  overflow: hidden;
}

.Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
  color: inherit;
}

@media (max-width: 768px) {

  .input-calendar,
  .DatePicker__input {
    font-size: 0.5rem;
  }
}

@media (max-width: 768px) {
  .Search-bar {
    border: 2px solid var(--primary-color);
    width: 110%;
    max-width: 400px;
    transform: translateX(-0.9rem);
    margin: auto;
    box-shadow: 0 0 5px hsl(0 0% 68%);
    border-radius: 0.875rem;
    display: flex;
    font-size: 0.6rem;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 0.6rem;
    box-sizing: border-box;
  }
}

@media (max-width: 768px) {
  .Search-button-section,
  .searchInputContainer,
  .Search-check-in {
    background-color: #f0f0f0;
    border-radius: 0.8rem;
    width: 100%;
    margin-bottom: 0.4rem;
  }

  .Search-check-in {
    height: 3.4rem;
  }

  .Search-location {
    background-color: #f0f0f0;
    border-radius: 0.8rem;
    width: 100%;
    margin-bottom: 0.4rem;
    height: 3rem;
    text-overflow: ellipsis;
  }

  .searchBar_inputfield {
    position: relative;
    background: none;
    border: none;
    text-align: center;
    width: 95%;
    height: 2.5rem;
    transform: translateY(10%);
    text-overflow: ellipsis;
    margin: auto;
  }
  /* kan problemen veroorzaken bij mobile-view */
  .searchBar_inputfield::placeholder {
    transform: translateX(2.5%)
  }

  .searchBar_inputfield * {
    margin: auto;
  }

  .Search-button-section {
    background-color: #f0f0f0;
    border-radius: 0.8rem;
    width: 100%;
    padding: 0rem;
    border: none;
    height: 3rem;
  }

  .searchInputContainer {
    height: 2.8rem;
  }
  .searchTitleGuest {
    transform: translateX(-0%);
    transform: translateY(-15%);
    text-align: center;
    text-overflow: ellipsis;
  }
  .Search-guestP {
    position: absolute;
  }
  .input-calendar {
    border: none;
    text-align: center;
    width: 100%;
    transform: translate(0%);
    height: 3.5rem;
  }
  /* tijdelijke oplossing het is nog niet responsive */
  .DatePicker__input {
    border: solid transparent;
    transform: translateX(-0%) translateY(-130%);
    width: 60vw;
    max-width: 62vw;
    box-sizing: border-box;
  }
  .searchbar-button {
    height: 2.7rem;
    width: 100%;
    border-radius: 10px;
    transform: translateX(-2.25%);
    margin-top: 0.5rem;
  }
  .Search-bar>div::before {
    background: transparent;
  }
  .DatePicker__calendarContainer {
    position: absolute;
    top: calc(-20% + -15.7rem);
    left: 48%;
    transform: translateX(-50%);
  }
  .DatePicker__calendarArrow {
    border-color: transparent;
  }
  .Search-ClearButton {
    margin-top: 0.5rem;
  }
  .Search-error-message {
    background-color: #f8d7da;
    color: #721c24;
    border: 2px solid #f5c6cb;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 16px;
    position: absolute;
    z-index: 1;
    height: 3rem;
    top: 40.6%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 82%;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .search-icon {
    margin-right: 12px;
    transform: translateY(15%);
  }
}

.search-text {
  display: none;
}

@media (max-width: 768px) {
  .search-text {
    display: inline;
  }
}
@media (max-width: 768px) {
  .mobile-search-button {
    display: block;
    width: 75vw;
    padding: 0.8rem;
    background-color: #ffffff;
    border: 1px solid #e2e2e4;
    border-radius: 0.8rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    color: #000;
    outline: none;
    text-align: left;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    transition: transform 0.3s ease-in-out;
    margin-bottom: 0.5rem ;
    text-overflow: ellipsis;
  }


  .Search-bar {
    display: none;
  }

  .bar-container .Search-bar {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .mobile-search-button {
    display: block;
    width: 90vw;
    padding: 0.8rem;
    background-color: #ffffff;
    border: 1px solid #e2e2e4;
    border-radius: 0.8rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    color: #000;
    outline: none;
    text-align: left;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    transition: transform 0.3s ease-in-out;
    margin-bottom: 0.5rem ;
    text-overflow: ellipsis;
  }

  @media screen and (min-width: 768px) {
    .mobile-search-button {
      width: 45vw;
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .Search-bar {
    width: 85%;
  }

  .Search-location {
    width: 28%;
  }

  .DatePicker__input {
    padding-left: 0%;
  }
}




