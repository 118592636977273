body.hide-header .app-header {
  display: none;
  visibility: hidden;
}

.domits-homepage .accocard-content {
  background-color: #f9f9f9;
}

.domits-homepage {
  background-color: #f9f9f9;
  position: relative;
  z-index: auto;
}

.domits-accommodationGroup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.domits-searchContainer {
  height: 87vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../images/villaHomepage.jpg");
  background-size: cover;
  background-position: 70% 100%;
  background-repeat: no-repeat;
  width: 100%;
}

.domits-searchTextCon {
  padding-top: 32vh;
  color: white;
  text-align: center;
  font-size: 4em;
  height: 15vh;
  width: 100%;
  font-weight: 100;
}

.domits-searchText {
  font-size: 3rem;
  padding-bottom: 2vh;
}

.domits-searchbarCon {
  padding-top: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.domits-subHead {
  font-size: 2.5rem;
  font-weight: bold;
  padding-left: 9.5rem;
}

.domits-iconsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  flex-wrap: wrap;
  height: 10vh;
  padding: 0 4%;
}

.domits-iconsContainerText {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
}

.domits-iconTextGroup {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.2rem;
}

.domits-iconsContainerText img {
  width: 30px;
}

.domits-communityContainer {
  text-align: center;
  margin-top: 1.5rem;
  padding-top: 4vh;
  padding-bottom: 8vh;
}

.domits-communityButtons {
  display: flex;
  align-items: center;
  font-size: 1.5vw;
  margin-top: 1.5rem;
  gap: 8vw;
  justify-content: center;
}

.domits-hostButton,
.domits-SearchButton {
  border: none;
  border-radius: 0.6em;
  padding: 8px 4vw;
  font-size: 0.9rem;
  cursor: pointer;
  font-size: 0.9vw;
}

.domits-hostButton {
  background-color: #003366;
  color: white;
}

.domits-SearchButton {
  background-color: #0d9813;
  color: white;
}

.domits-trendingContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 25px;
  margin-left: 7vw;
  padding-bottom: 2vh;
}

.domits-trendingContainer > div {
  width: 12vw;
  padding: 8px;
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 8px;
  color: #555;
  font-size: 16px;
  margin-left: 4%;
}

.domits-trendingContainer > div:hover::after {
  content: attr(data-popup-text);
  display: block;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 15px;
  position: absolute;
  white-space: normal;
  z-index: 10;
  width: 20%;
  height: auto;
  overflow: auto;
  transform: translateX(50%);
  transform: translateY(90%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.domits-communityHead {
  font-size: 3vw;
  font-weight: 450;
}

.domits-communityGroup {
  font-size: 1.5vw;
  font-weight: 300;
  width: 25%;
  height: 15vh;
  text-align: left;
  padding-left: 38vw;
}

@media (max-width: 1024px) {

}

@media (max-width: 1440px) {
  .domits-accocard {
    height: auto;
  }
}

@media (max-width: 1024px) {
  .domits-accocard {
    width: clamp(220px, 20vw, 300px);
  }

  .domits-searchTextCon {
    font-size: 5em;
  }

  .domits-communityContainer {
    padding: 2vh 5vw;
  }
}

@media (max-width: 768px) {
    .domits-accocard {
      width: 40%;
      height: auto;
    }

    .domits-searchText {
      font-size: 0.6em;
    }

  .domits-searchTextCon {
    font-size: 3em;
  }

  .domits-communityContainer {
    padding: 2vh 3vw;
  }

  .domits-communityButtons {
    flex-direction: column;
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .domits-subHead {
    font-size: 2rem;
    margin-left: 2vw;
    text-align: center;
  }

  .domits-accommodationGroup {
    padding-left: 0vh;
    flex-direction: column;
}

  .domits-trendingContainer {
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin: 0 auto;
    padding: 10px 0;
  }

  .domits-trendingContainer > div {
    width: 90%;
    font-size: 16px;
  }

  .domits-searchTextCon {
    font-size: 2.5em;
    padding-top: 20vh;
  }

  .domits-searchText {
    font-size: 1em;
  }

  .domits-communityContainer {
    padding: 2vh 5vw;
    text-align: center;
  }

  .domits-communityButtons {
    flex-direction: column;
    gap: 10px;
    margin: 10px auto;
  }

  .domits-hostButton,
  .domits-SearchButton {
    font-size: 1rem;
    padding: 10px 20px;
    width: 80%;
    margin: 5px auto;
  }

  .domits-searchbarCon {
    padding-top: 15px;
  }

  .domits-iconsContainer {
    flex-direction: column;
    gap: 15px;
    height: auto;
    padding: 10px 5vw;
  }

  .domits-iconsContainerText {
    flex-direction: column;
    gap: 10px;
    font-size: 1.2rem;
    text-align: center;
  }

  .domits-communityHead {
    font-size: 1.8rem;
  }

  .domits-communityGroup {
    font-size: 1rem;
    width: 85%;
    margin: 0 auto;
    text-align: center;
    padding-left: 6vw;
    height: 5vh;
  }
}

@media (max-width: 480px) {
  .domits-searchTextCon {
    font-size: 2em;
  }

  .domits-searchText {
    font-size: 1em;
  }

  .domits-subHead {
    font-size: 1.5rem;
    margin: 10px auto;
    text-align: center;
  }

  .domits-trendingContainer > div {
    font-size: 14px;
  }

  .domits-communityHead {
    font-size: 1.5rem;
  }

  .domits-hostButton,
  .domits-SearchButton {
    font-size: 0.8rem;
    padding: 8px 15px;
  }

  .domits-iconsContainerText {
    font-size: 1rem;
  }

  .domits-searchContainer {
    height: 50vh;
  }

  .domits-trendingContainer > div:hover::after {
    width: 60%;
    transform: translateX(0%);
    transform: translateY(0%);
  }
}

