/* Login.css */

.registerButton {
    width: 110%;
    padding: 8px 15px;
    margin-top: 15px;
    background: var(--secondary-color);
    box-shadow: none;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 18px;
    display: block;
    text-align: center; 
    text-decoration: none; 
    cursor: pointer;
}

.countryCodeDropdown {
    width: 40%; 
    padding: 8px 12px;
    border: 1px solid var(--secondary-color); 
    border-radius: 5px;
    background-color: white; 
    font-size: 14px; 
    color: black; 
    outline: none; 
    appearance: none; 
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNNC42NyA2bDUuMzMgNS4zMyA1LjMzLTUuMzNhLjUuNSAwIDAwLS43MDcuNzE2bC01LjY3IDUuNjctNS42Ny01LjY3YS41LjUgMCAwMC43MDctLjcwN3oiIGZpbGw9IiMzMzMiLz48L3N2Zz4='); 
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
    position: relative;
    z-index: 1000;
}

.countryCodeDropdown:focus {
    border-color: #4a86c3; 
    box-shadow: 0 0 5px rgba(74, 134, 195, 0.5); 
}

.countryCodeDropdown:hover {
    border-color: #4a86c3; 
    cursor: pointer; 
}

.registerButton:hover {
    background: #4a86c3;
}

.registerTitle {
    color: black;
    font-size: 30px;
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    line-height: 54px;
    word-wrap: break-word;
    margin-top: 30px;
}

.registerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.registerForm {
    margin-top: 20px;
    min-width: 300px;
    width: 25%;
    padding: 10px 30px;
    margin-bottom: 50px;
    box-sizing: border-box;
}

label {
    font-size: 18px;
}

    .registerInput {
        width: 100%;
        height: auto;
        padding: 8px 15px;
        margin-top: 5px;
        margin-bottom: 5px;
        background: white;
        box-shadow: none;
        border-radius: 5px;
        border: 1px solid var(--secondary-color);
        justify-content: flex-start;
        align-items: center;
        display: inline-flex;
    }

.alreadyAccountText {
    color: black;
    margin-top: 10px;
    font-size: 14px;
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word;
    align-self: flex-start;
}
.inputError {
    animation: shake 0.3s linear;
    border-color: red;
}

.inputShake {
    animation: shake 0.5s;
}

@keyframes shake {
    0% { margin-left: 0px; }
    25% { margin-left: 5px; }
    75% { margin-left: -5px; }
    100% { margin-left: 0px; }
}

.errorText {
    color: rgb(255, 255, 255);
    font-size: 16px;
    margin-top: 5px;
}

.signOutButton {
    background-color: #003366;
    color: #fff;
    height: 45px;
    width: 150px;
    border-radius: 5px;
    box-shadow: none;
    padding: 10px;
    margin: 0 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

.signOutButton:hover {
    background-color: #335e89;
    cursor: pointer;
    transition: background-color .3s ease-out;
}

.signOutDiv {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 4%;
    padding-top: 4%;
}

.strength-container {
    display: none; 
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

#strength-bar {
    height: 10px; 
    border-radius: 5px; 
    transition: width 0.3s ease; 
}

.strength-text {
    margin-top: 5px; 
    font-size: 10px; 
    color: #333;
}


.requirements {
    margin-top: 5px; 
}

.requirements label {
    display: flex; 
    align-items: center;
    margin: 3px 0; 
    font-size: 10px;
}


.bad {
    width: 10%;
    background-color: red;
}

.weak {
    width: 33%;
    background-color: red;
}

.medium {
    width: 66%;
    background-color: orange;
}

.strong {
    width: 100%;
    background-color: green;
}
