.contentContainerCalendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    .calendarDropdown {
        height: auto;
        margin: 10px;
        min-width: 155px;
        display: flex;
        flex-direction: column;
        width: 80%;
    }
    .locationBox {
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        font-size: 1.1em;
        width: 100%;
        max-width: 100%;
        padding: 0.75em;
        margin: 1em auto;
    }
    .selectOption {
        display: flex;
        text-align: center;
    }
    .exportICal {
        background-color: #003366;
        color: #fff;
        padding: 10px;
        border-radius: 40px;
        font-size: 1em;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin: 1em 0;
    }
    .calendarHeader {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 1em 0;
        gap: 1rem;
    }
    .spinnerLoader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }
}
