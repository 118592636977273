#card-visibility {
  padding-top: 30px;
}

.full-visibility {
  width: 100%;
}

.accocard {
  width: 18%;
  margin: 1%;
  margin-top: 10px;
  background-color: #fff;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  transition: all 0.5s ease-in-out;
  position: relative;
  z-index: 1;
}

.accocard:hover {
  cursor: pointer;
}

.accocard img {
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 15px;
  display: block;
  transition: transform 0.4s ease-in-out;
}

.accocard:hover img {
  transform: scale(1.05);
}

.accocard:hover .accocard-title {
  background: rgba(13, 152, 20, 0.8);
}

.accocard-content {
  padding: 15px 0;
  overflow: hidden;
}

.accocard-title {
  width: 90%;
  background: rgba(0, 0, 0, 0.383);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  padding: 10px;
  border-radius: 15px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  bottom: 160px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 18px;
  font-weight: 500;
  transition: background 0.4s ease-in-out;
  position: absolute;
  z-index: 2;
}

.accocard-price {
  font-size: 18px;
  color: var(--primary-color);
  margin-bottom: 8px;
  font-weight: 500;
}

.accocard-detail {
  margin-bottom: 8px;
  width: 300px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3em;
}

.accocard-specs {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.accocard-size {
  font-size: 14px;
  color: #777;
  margin: 0 3% 0 0;
}

.why-domits-button {
  position: fixed;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
  z-index: 1000;
}

.why-domits-link {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 10px;
  text-decoration: none;
  border-radius: 0 5px 5px 0;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-align: center;
}

.why-domits-link:hover {
  opacity: 90%;
  text-decoration: none;
}

@media (max-width: 1292px) {
  .accocard {
    width: 23%;
  }
}

@media (max-width: 856px) {
  .accocard {
    width: 48%;
  }
  .accocard-specs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }
  .why-domits-button {
    display: none;
  }
}

@media (max-width: 480px) {
  .accocard {
    width: 90%;
    margin: 0 auto;
    display: block;
  }
  .accocard-detail {
    width: 100%;
  }
  .accocard-size {
    font-size: 12px;
  }

  .accocard-specs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }
}

.accocard-share-button, .accocard-like-button {
  position: absolute;
  top: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.accocard-share-button {
  left: 10px;
}

.accocard-like-button {
  right: 10px;
}

.accocard-share-button:hover, .accocard-like-button:hover {
  background-color: rgba(255, 255, 255, 0.9);
  transform: scale(1.1);
}

.accocard:hover .accocard-share-button, .accocard:hover .accocard-like-button {
  opacity: 1;
  transform: scale(1);
}

.accocard .accocard-share-button, .accocard .accocard-like-button {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.swiper-button-next, .swiper-button-prev,.swiper-button-prev-swiper-button-disabled {
  z-index: 30;
  cursor: pointer;
  color: #333;
  pointer-events: auto;
  padding: 5px;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  font-size: 10px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.swiper:hover .swiper-button-next,
.swiper:hover .swiper-button-prev {
  opacity: 1;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
}
.swiper-button-next::after, .swiper-button-prev::after {
  color: #000;
  font-size: 10px !important;
}

.swiper-pagination-bullet {
  background: rgba(0, 0, 0, 0.5);
  width: 10px;
  height: 10px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  z-index: 15 !important;
}

.swiper-pagination-bullet-active {
  background: #007bff;
  opacity: 1;
  z-index: 15 !important;
}

body {
  position: relative;
  height: 100%;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
