@keyframes progressBar {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}

.Toast {
  position: absolute;
  top: 1%;
  right: 1%;
  margin-top: 10px;
  padding: 20px;
  border-radius: 5px;
  font-size: 1em;
  width: 20%;
  opacity: 1;
  z-index: 1000;
  visibility: visible;
  transition: opacity 0.5s ease-in, visibility 0.5s ease-in;
}

.Toast.hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in, visibility 0.5s ease-in;
}

.Toast-content {
  padding-bottom: 10px;
}

.progress-bar {
  height: 3px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.progress-bar.active {
  animation: progressBar 3s linear forwards;
}

.progress-bar.hidden {
  display: none;
}

.success {
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  color: #155724;
}

.error {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
}

.warning {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
}

.info {
  background-color: #d1ecf1;
  border: 1px solid #bee5eb;
  color: #0c5460;
}

.progress-bar.success {
  background-color: #155724;
}

.progress-bar.error {
  background-color: #721c24;
}

.progress-bar.warning {
  background-color: #856404;
}

.progress-bar.info {
  background-color: #0c5460;
}

@media (max-width: 575.98px) {
  .Toast{
    top: 146.66px;
    width: 75%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .Toast{
    top: 151.76px;
    width: 70%;
  }
}

/* Large devices (laptops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (desktops, 1200px and up) */
@media (min-width: 1200px) {
}
