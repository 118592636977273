.rp-revpar-card {
    width: 320px;
    padding: 20px;
    margin: 20px auto;
    border: 1px solid #ddd;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    text-align: center;
    height: 460px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.rp-revpar-card h3 {
    font-size: 20px;
    font-weight: 400 ;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.rp-revpar-details p {
    margin: 20px 10px;
    font-size: 20px;
    font-weight: normal;
    color: #444;
}

.rp-revenue-amount {
    font-size: 24px;
    font-weight: bold;
    color: #000;
}
.rp-revpar-card:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
.rp-custom-dates input{
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: normal;
    color: #555;
}
.rp-custom-dates label{
    font-size: 15px;
    font-weight: normal;
    color: #555;
}