.dashboardHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.welcomeMsg {
    padding: 0;
}
.dashboardHost {
    display: flex;
    justify-content: flex-start;
    gap: 5rem;
    width: 100%;
}
.dashboardContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4em;
}
.dashboardLeft {
    display: flex;
    flex-direction: column;
    gap: 2em;
    animation: fade-up 2s;
}
.dashboardRight {
    animation: fade-up 4s;
}
.personalInfoContent {
    display: flex;
    flex-direction: column;
    margin-left: 1px;
    width: 100%;
}
.personalInfoBox {
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    width: auto;
    font-size: 0.9em;
}
.personalInfoBox span {
    margin-right: auto;
}
.buttonBox {
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 5%;
    border-radius: 1rem;
}
.dashboardCard {
    height: 35vh;
    width: 17vw;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0.5em;
    position: relative;
}
.accommodationText {
    padding: 0.25em;
    width: 80%;
    background-color: black;
    backdrop-filter: blur(10px);
    border-radius: 0.5em;
    text-align: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-self: center;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.accommodationTitle {
    color: white;
    margin: 0;
    font-size: 0.75em;
    background-color: black;
}
.accommodationLocation {
    color: white;
    font-size: 0.6em;
    margin-top: 1%;
}
.accommodationImg {
    max-height: 70%;
    max-width: none;
    border-radius: 0.5em 0.5em 0 0;
    padding: 0;
    align-self: center;
    width: 100%;
    height: 70%;
}
.accommodationDetails {
    padding: 10px;
}
.accommodationDetails p {
    font-size: 0.75rem;
}
.isDrafted {
    color: var(--secondary-color);
}
.isLive {
    color: var(--secondary-color);
}
.greenBtn {
    color: white;
    background-color: var(--secondary-color);
    border: none;
    padding: 10px 25px;
    border-radius: 10px;
    cursor: pointer;
}
.greenBtn:hover {
    opacity: 90%;
}
.infoBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
}
.infoIcon {
    max-width: 1.5em;
    max-height: 1.5em;
}
.alertMessage {
    margin: 15px;
    font-weight: bold;
    text-align: center;
    font-size: 1.5rem;
}
.wijzer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 10px;
    align-items: center;
    width: 200px;
    border: 0.5px solid black;
    border-radius: 15px;
    padding: 5px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    font-size: 0.75em;
    text-align: center;
}
.header {
    font-size: 1.75em;
}
.imgSliderImage {
    border-radius: 2em;
    height: auto;
    max-height: 15vh;
    max-width: 25vw;
    opacity: 0;
    padding: 1em;
    transition: opacity 1s ease-in-out;
}
.visible {
    opacity: 1;
}
.hostListingContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4em;
}
.listingBody {
    display: flex;
    flex-direction: column;
}
.listingsDisplay {
    box-shadow: 0 2px 8px 0 #63636333;
    border-radius: 1rem;
    padding: 1rem;
    margin-top: 5%;
}
.loadingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
}
.spinner {
    max-width: 25vw;
    max-height: 25vh;
}

@media only screen and (max-width: 768px) {
    .dashboardHeader {
        flex-direction: column;
        align-items: center;
    }
    .dashboardHost {
        flex-direction: column;
        gap: 2rem;
    }
    .dashboardContainer {
        flex-direction: column;
        gap: 2em;
    }
    .dashboardLeft, .dashboardRight {
        width: 100%;
        gap: 1em;
    }
    .dashboardCard {
        height: auto;
        width: 80vw;
    }
    .accommodationText {
        width: 90%;
    }
    .accommodationImg {
        height: auto;
    }
    .buttonBox {
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
    }
    .greenBtn {
        width: 100%;
    }
    .infoBox {
        flex-direction: column;
        align-items: flex-start;
    }
    .hostListingContainer {
        flex-direction: column;
        gap: 2rem;
    }
    .listingsDisplay {
        margin-top: 2%;
    }
    .imgSliderImage {
        max-width: 100%;
        max-height: 20vh;
    }
}

@media only screen and (max-width: 480px) {
    .dashboardCard {
        height: auto;
        width: 90vw;
    }
    .accommodationText {
        font-size: 0.65em;
    }
    .personalInfoContent{
        display: flex;
        flex-direction: column;
        margin-left: 0.5px;
        width: 90vw;
    }
    .accommodationTitle, .accommodationLocation {
        font-size: 0.6em;
    }
    .dashboardContainer {
        gap: 1em;
    }
    .welcomeMsg {
        font-size: 0.8rem;
    }
    .alertMessage {
        font-size: 1.2rem;
    }
    .header {
        font-size: 1.5em;
    }
    .wijzer {
        width: 150px;
        font-size: 0.65em;
    }
}
