.image-gallery-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.selected-image {
  position: relative;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}

.selected-image img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 15px;
  object-fit: cover;
  aspect-ratio: 16 / 9; 
}

.image-thumbnails {
  display: flex;
  flex-wrap: wrap; 
  gap: 10px; 
}

.image-thumbnails img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  opacity: 0.6;
  border-radius: 15px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.image-thumbnails img.active,
.image-thumbnails img:hover {
  opacity: 1;
  transform: scale(1.05); 
}

.share-button,
.like-button {
  position: absolute;
  top: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.share-button {
  left: 10px;
}

.like-button {
  right: 10px;
}

.like-button:hover,
.share-button:hover {
  background-color: rgba(200, 200, 200, 0.8);
  transform: scale(1.1); 
}


.like-button:focus,
.share-button:focus {
  outline: 2px solid #007aff;
}

@media (max-width: 600px) {
  .image-thumbnails img {
    width: 80px;
    height: 80px;
  }

  .share-button,
  .like-button {
    width: 30px;
    height: 30px;
  }
}
