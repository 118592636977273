.cbc-chat-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f1f1f1;
}

.cbc-chat-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px; /* Larger maximum width */
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 85vh; /* Take up 85% of the viewport height */
}


.cbc-chat-status {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.cbc-chat-messages {
  flex: 1; /* Flex-grow: take up the remaining space */
  overflow-y: auto;
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.cbc-message-wrapper {
  margin-bottom: 10px;
}

.cbc-message-wrapper.cbc-user {
  text-align: right;
}

.cbc-bubble {
  max-width: 60%;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
  display: inline-block;
  word-wrap: break-word;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.cbc-bubble.user {
  background-color: #28a745;
  color: white;
}

.cbc-bubble.ai {
  background-color: #e0e0e0;
}

.cbc-bubble.employee {
  background-color: #007bff;
  color: white;
}

.cbc-bubble.system {
  background-color: #ffc107;
}

.cbc-sender {
  font-weight: bold;
  margin-bottom: 5px;
}

.cbc-accommodation-tiles {
  display: flex;
  flex-wrap: wrap;
}

.cbc-accommodation-tile {
  width: calc(50% - 10px);
  margin: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
}

.cbc-accommodation-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.cbc-accommodation-details {
  padding: 10px;
}

.cbc-chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background: #f9f9f9;
  /* Ensure it remains visible */
}

.cbc-chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.cbc-chat-input button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  margin-left: 10px;
}

.cbc-decision-box {
  padding: 10px;
  background-color: #f1f1f1;
  border-top: 1px solid #ddd;
  text-align: center;
}

.cbc-decision-box p {
  margin-bottom: 10px;
}

.cbc-decision-box button {
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
}

.cbc-predefined-messages, .cbc-sub-questions, .cbc-sub-sub-questions {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.cbc-predefined-message-button, .cbc-sub-question-button, .cbc-sub-sub-question-button {
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
}

.cbc-predefined-message-button:disabled, .cbc-sub-question-button:disabled, .cbc-sub-sub-question-button:disabled {
  background: #ddd;
  cursor: not-allowed;
}

.cbc-chat-status {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  position: relative;
}

.cbc-close-chat-button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.cbc-close-chat-button:hover {
  background-color: #c82333;
}
