.disclaimers{
    padding: 50px;
    padding-left: 150px;
    font-weight: 450;
    width: 950px;
}

.disclaimers input{
    width: 800px;
    border-radius: 15px;
    padding: 10px;
    border: 1px solid;
    justify-content: center;
    align-items: center;
}

.policy{
    padding: 50px;
    padding-left: 150px;
    font-weight: 450;
    width: 950px;
    line-height: 150%;
}

.policy input{
    width: 800px;
    border-radius: 15px;
    padding: 10px;
    border: 1px solid;
    justify-content: center;
    align-items: center;
}

.terms{
    padding: 50px;
    padding-left: 150px;
    font-weight: 450;
    width: 950px;
    line-height: 150%;
}

.terms input{
    width: 800px;
    border-radius: 15px;
    padding: 10px;
    border: 1px solid;
    justify-content: center;
    align-items: center;
}

.bold{
    font-weight: bold;
}