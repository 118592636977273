.hr-page-body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.hr-container {
    margin: 0 auto;
    padding: 20px;
    flex: 1; /* Takes up available space */
}

.hr-h1 {
    font-size: 2em;
    margin-bottom: 20px;
}

/* Host Revenue Section */
.hr-host-revenues {
    display: flex;
    flex-direction: row;
    /* Remove the fixed height of 125vh */
    min-height: 0; /* Allows content to fit properly */
    height: 100%; /* Takes full height of container */
}

.hr-pages {
    margin-right: 20px;
}

.hr-content {
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-auto-rows: auto; /* This allows new rows to be created as needed */
    gap: 20px;
    margin-top: 20px;
}

.hr-revenue-overview {
    display: grid;
    gap: 10px;
    background-color: #ffffff;
    padding: 20px;
    width: 30vh;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hr-revenue-overview h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.ro-revenue-card {
    padding: 10px 15px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    background-color: #f8f9fa;
    text-align: center;
}

.hr-monthly-comparison {
    grid-column: 2 / 3;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hr-monthly-comparison h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

/* Cards Section */
.hr-cards {
    grid-column: 1 / 3;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hr-card {
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Stripe Status Section */
.hr-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.hr-button:hover {
    background-color: #0056b3;
}

/* Summary Section */
.hr-summary {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.hr-summary h4 {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.hr-summary table {
    width: 100%;
    border-collapse: collapse;
}

.hr-summary table th,
.hr-summary table td {
    padding: 10px;
    border: 1px solid #dee2e6;
    text-align: left;
}

.hr-summary table th {
    background-color: #f1f3f5;
}

.hr-summary .hr-download-button {
    text-align: right;
    margin-top: 20px;
}

.hr-summary .hr-download-button button {
    padding: 10px 20px;
    font-size: 1em;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #ffffff;
    cursor: pointer;
}

.hr-revenue-spinner-container {
    position: relative !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    height: 100vh !important;
    padding-left: 700px !important;  /* Increase this value to move it right */
}

/* Force Spinner Thickness */
.hr-revenue-spinner {
    border-width: 10px !important;  /* Make the spinner thicker */
}

.hr-booking-trends {
    grid-column: 2 / 3;  /* Places it in the second column like Monthly Comparison */
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.hr-booked-nights {
    display: grid;
    gap: 10px;
    background-color: #ffffff;
    padding: 20px;
    width: 30vh;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-height: 400px;
}

@media (max-width: 1024px) {
    .hr-content {
        grid-template-columns: 1fr;
    }

    .hr-cards {
        grid-template-columns: repeat(2, 1fr);
    }

    .hr-pages {
        width: 100%;
        margin-right: 0;
    }
    .hr-booking-trends {
        grid-template-columns: 1fr;
    }
    .hr-booked-nights {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .hr-content {
        grid-template-columns: 1fr;
    }

    .hr-cards {
        grid-template-columns: 1fr;
    }

    .hr-pages {
        width: 100%;
        margin-right: 0;
    }

}


@media (min-width: 1200px) {
    .hr-content {
        grid-template-columns: 1fr 2fr;
    }

    .hr-cards {
        grid-template-columns: repeat(3, 1fr);
    }
}
