.faq-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 50px;
} 

.faqHeader3 {
  font-size: clamp(20px, 4vw, 32px);
}

.faqHeader4 {
  font-size: clamp(10px, 4vw, 25px);
  font-weight: bolder;
}
 
.faq-button-container {
  display: flex;
  justify-content: left; 
  align-items: left;
  margin-bottom: 50px;
  gap: 20px;
}

.faqButton{
  display: flex;
  flex-direction: row ;
  color: white;
  border-radius: 8px;
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  padding: 10px 20px;
  margin: 10px 0 0 0;
  cursor: pointer;
  max-width: 200px;
  flex: 1;
  text-align: center; 
  font-weight: 500;
  font-size: 15px;
}

.foundResults {
  font-size: 24px;
  color: black;
  margin: 0;
}

.faq-box {
  min-width: 90%;
  border-radius: 10px;
  margin: 10px 0;
  padding: 15px;
  cursor: pointer;
  transition: max-height 0.4s ease; 
  overflow: hidden;
}

.faqButton:hover {
  opacity: 90%;
}

.category{
  color: white;
  border-radius: 8px;
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  padding: 10px 20px;
  margin: 30px 20px;
  cursor: pointer;
}

.faq-box {
  width: 50%;
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  margin: 10px 0;
  padding: 15px;
  cursor: pointer;
  transition: max-height 0.4s ease; 
  overflow: hidden;
}

.question {
  font-weight: bold;
  margin-bottom: 8px;
}

.answer {
  overflow: hidden;
  transition: max-height 0.4s ease-in-out; 
}

@media (max-width: 900px) {
  .faq-box {
    width: 100%;
  }
}
