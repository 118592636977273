.about {
    width: 90%;
    margin: auto;
    margin-top: 40px;
    color: #000;
    font-size: 20px;
    font-style: normal;
}

.about__subtitle-container h3, h4{
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    font-size: 1em;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.about__fact-subtitle {
    margin: 0;
    font-size: clamp(0.8rem, 1.2vw + 0.8rem, 1.5rem);
    color: white;
}

.about__factsbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    gap: 3.75rem;
}

.ref{
    text-decoration: underline;
}

.about__title-container h2, h3 {
    flex-direction: column;
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    font-size: 1.5em;
    margin: 0.5em 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.about__title{
    text-align: left;
}

.highlightH2 {
    font-size: 1.40em;
}

.highlightText {
    color: #003366;
    font-style: oblique;
}

.about__fact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 49%;
    background-color: var(--primary-color);
    border-radius: 20px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
}

.about__text-container {
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.about__text {
    font-size: 1.25rem;
    color: black;
    margin: 0rem;
}

.about__who-we-are-title {
    text-align: left;
}

.about__who-we-are-title--margintop {
    margin-top: 2rem;
    font-weight: 400;
    font-size: 1.8em;

}

.about__who-we-are-subtitle--marginbottom {
    margin-bottom: 4rem;
    text-align: left;
}

.about__text--margintop {
    margin-top: 1.2rem;
}

.about__fact-text {
    margin-top: 20px;
    color: white;
}

.about__fact-title {
    margin: 0;
    font-size: clamp(0.8rem, 1.2vw + 0.8rem, 2rem);
    color: white;
}

.about__fact-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
    min-height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.about__fact-image {
    height: auto;
    object-fit: contain;
}

.about__crew {
    text-align: center;
}

.about__team {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.about__member {
    width: 20%;
    margin-bottom: 20px;
}

.about__member>p:nth-of-type(1) {
    color: var(--primary-color);
}

.about__avatar {
    height: 100px;
    width: 100px;
    background-color: white;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 10px;
}

.about__avatar-image {
    height: 100px;
    width: 100px;
    border-radius: 50%;
}

.about__footer {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.about__footer p {
    color: black;
    font-size: 1.25rem;
}

.about__footer p:first-child {
    margin-right: 50px;
}

@media (max-width: 1440px) {

    .about__text-container,
    .about__subtitle-container,
    .about__title-container {
        max-width: 1280px;
    }
}

@media (max-width: 850px) {

    .about__member {
        width: 100%;
    }

    .about__factsbox {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about__fact {
        width: 90%;
    }

    .about__fact-header {
        flex-direction: column-reverse;
    }

    .about__footer {
        flex-direction: column;
    }

    .about__footer p:first-child {
        margin: 0 0 25px 0;
    }

    .about__footer p {
        width: 100%;
    }

    .about__fact-subtitle {
        text-align: center;
    }

    .about__fact-text {
        text-align: center;
    }
}

@media (max-width: 600px) {
    .about__title-container {
        display: block;
        text-align: center;
    }

    .about__subtitle-container {
        text-align: center;
    }

    .about__text {
        text-align: center;

    }

    .about__text--margintop {
        margin-top: 1.5rem;
    }

    .about__footer {
        text-align: center;
    }
}