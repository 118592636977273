.why-domits {
    width: 100%;
}

.why-domits__title-text-container {
    padding: 100px 50px;
    padding-bottom: 1rem;
}

.highlightText {
    color: #003366;
}

.why-domits__h3 {
    font-size: 2.5rem;
    margin: 0;
    font-weight: 400;
}

.why-domits__h4 {
    font-size: 1.75rem;
    font-weight: 400;
    margin-top: 1.6rem;
    margin-bottom: 3rem;
}

.why-domits__p {
    font-size: 1.25rem;
    font-weight: 400;
    margin-top: 1.6rem;
    margin-bottom: 3rem;
    color: black;
}

.why-domits__cards-container {
    min-height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5.6rem;
    flex-wrap: wrap;
}

.why-domits__card {
    width: 39.2%;
    background: var(--primary-color);
    min-height: 9.3rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    gap: 2rem;
}

.why-domits__card-p {
    font-size: 1.25rem;
    font-weight: 400;
    color: white;
}

.why-domits-bar {
    background-color: var(--primary-color);
    color: white;
    align-items: center;
    height: 5vh;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 10%;
}

.why-domits__flex-container.guest,
.why-domits__flex-container.host,
.why-domits__flex-container.dev,
.why-domits__flex-container.growth {
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.why-domits__div_host{
    font-size: 1.5rem;
}

.why-domits__section.guest:first-child,
.why-domits__section.host:first-child,
.why-domits__section.dev:first-child,
.why-domits__section.growth:first-child {
    flex: 1 1 100%;
    text-align: left;
    margin-bottom: 20px;
    padding: 15px;
}

.why-domits__section:not(:first-child) {
    flex: 1 1 calc(50% - 20px);
    box-sizing: border-box;
    padding: 15px;
    max-width: calc(50% - 20px);
}

.why-domits__flex-container.host,
.why-domits__flex-container.growth {
    background-color: #f1efef;
}

.navigation-arrows {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.navigation-arrows {
    text-decoration: none;
    font-size: 1.25rem;
    color: #003366;
    font-weight: bold;
}

.navigation-arrows:hover {
    color: #0055aa;
    text-decoration: underline;
}

@media (max-width: 1024px) {
    .why-domits__title-text-container {
        padding: 5rem;
    }

    .why-domits__h3 {
        font-size: 2.25rem;
    }

    .why-domits__h4 {
        font-size: 1.50rem;
    }

    .why-domits__p {
        font-size: 1.125rem;
    }

    .why-domits__card-p {
        font-size: 1.125rem;
    }
} 

@media (max-width: 955px) {
    .why-domits__cards-container {
        min-height: 20rem;
        gap: 5.5rem;
        flex-wrap: wrap;
        margin-bottom: 5rem;
    }

    .why-domits__card {
        width: 70%;
    }

    .why-domits__h3,
    .why-domits__h4,
    .why-domits__p {
        text-align: center;
    }
}

@media (max-width: 768px) {
    .why-domits__title-text-container {
        padding: 4rem;
    }

    .why-domits__h3 {
        font-size: 1.75rem;
        text-align: center;
    }

    .why-domits__h4 {
        font-size: 1.30rem;
        text-align: center;
    }

    .why-domits__p {
        font-size: 0.90rem;
        text-align: center;
    }

    .why-domits__card {
        width: 100%;
        min-height: 7rem;
    }

    .why-domits__card-p {
        font-size: 1rem;
    }

    .highlightText {
        font-weight: 500;
    }

    .why-domits__section.guest,
    .why-domits__section.host,
    .why-domits__section.dev,
    .why-domits__section.growth {
        flex: 1 1 100%;
    }
}

@media (max-width: 480px) {
    .why-domits__title-text-container {
        padding: 2rem;
    }

    .why-domits__h3 {
        font-size: 1.5rem;
    }

    .why-domits__h4 {
        font-size: 1.25rem;
    }

    .why-domits__p {
        font-size: 0.875rem;
    }

    .why-domits__card {
        min-height: 5rem;
    }

    .why-domits__card-p {
        font-size: 0.875rem;
    }
}
