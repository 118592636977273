.chat__body {
    display: flex;
    flex-direction: row;
    gap: 5%;
}
.chat__body .contactList {
    display: flex;
    flex-direction: column;
    width: 15vw;
}
.chat__body .contactList .switcher {
    display: flex;
    flex-direction: column;
}
.chat__body .contactList .switcher .switchButton {
    padding: 2.5%;
    border-radius: 2rem;
    margin-bottom: 5%;
}
.chat__body .contactList .displayBody {
    background: white;
    border: 1.5px solid #eee;
    border-radius: 1rem;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: start;
    padding: 2rem;
}
.chat__body .contactList .displayBody .displayItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: var(--primary-color);
    border-radius: 1rem;
    color: white;
}
.chat__body .contactList .displayBody .displayItem div {
    color: white;
}
.chat__body .contactList .displayBody .displayItem {
    align-items: center;
    display: flex;
    flex-direction: column;
}
.chat__body .contactList .displayBody .fullName {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}
.chat__body .contactList .displayBody .displayItem:hover {
    background-color: white;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    cursor: pointer;
}
.chat__body .contactList .displayBody .displayItem:hover div {
    color: var(--primary-color);
}
.chat__body .contactList .displayBody .pendingContactItem:hover {
    background-color: var(--primary-color);
    color: white;
    border: none;
    cursor: default;
}
.chat__body .contactList .displayBody .pendingContactItem:hover div {
    color: white;
}
.chat__body .contactList .displayBody .selectedUser {
    color: var(--primary-color);
    background-color: white;
    border: 1px solid var(--primary-color);
}
.chat__body .contactList .displayBody .selectedUser div {
    color: var(--primary-color) !important;
}

.chat__body .selected {
    border: none;
    color: white;
    background-color: var(--primary-color);
}
.chat__body .disabled {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    background-color: white;
}
.chat__body button {
    align-self: auto;
}
.chat__body .verticalButtonBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5%;
}
.chat__body .horizontalButtonBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5%;
    width: 100%;
}
.chat__body .accept {
    background-color: mediumseagreen;
    color: white;
}
.chat__body .accept:hover {
    background-color: white;
    color: mediumseagreen;
}
.chat__body .deny {
    background-color: red;
    color: white;
}
.chat__body .deny:hover {
    background-color: white;
    color: red;
}
.chat__body .roundButton {
    border-radius: 50%;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    height: 1.5em;
    line-height: 1em;
    outline: none;
    text-align: center;
    transition: background-color .3s;
    width: 1.5em;
}
.chat__body .mainButton {
    border-radius: 0.5rem;
    color: white;
    background-color: var(--primary-color);
    border: none;
    padding: 0.5rem;
}
.chat__body .mainButton:hover {
    color: var(--primary-color);
    background-color: white;
    border: 1px solid var(--primary-color);
}
.hostButton {
    border-radius: 0.5rem;
    color: white;
    background-color: #052C5A;
    border: none;
    padding: 0.5rem;
    margin-top: 1.5em;
}
.hostButton:hover {
    color: #052C5A;
    background-color: white;
    border: 1px solid #052C5A;
}

.accoDisplay {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1.5px solid #eee;
    height: 85%;
    border-bottom-right-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    width: 40%;
}
.accoDisplay h4 {
    align-self: center;
}
.accoDisplay .textDisplay {
    display: flex;
    flex-direction: column;
    margin: 5%;
    align-items: center;
}
.accoDisplay .textDisplay h3 {
    margin: 0;
    text-align: center;
}

.chatContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    .chat__body {
        flex-direction: column;
        gap: 2rem;
    }
    .chat__body .contactList {
        width: 100%;
    }
    .chat__body .contactList .displayBody {
        padding: 1rem;
        gap: 0.5rem;
    }
    .accoDisplay {
        width: 100%;
        height: auto;
    }
    .accoDisplay .textDisplay {
        margin: 2%;
    }
}

@media only screen and (max-width: 480px) {
    .chat__body {
        flex-direction: column;
        gap: 1rem;
    }
    .chat__body .contactList {
        width: 100%;
    }
    .chat__body .contactList .switcher .switchButton {
        padding: 2%;
        margin-bottom: 2%;
    }
    .chat__body .contactList .displayBody {
        padding: 0.5rem;
        gap: 0.25rem;
    }
    .chat__body .contactList .displayBody .displayItem {
        padding: 0.25rem;
    }
    .chat__body .verticalButtonBox,
    .chat__body .horizontalButtonBox {
        gap: 2%;
    }
    .accoDisplay {
        width: 100%;
        height: auto;
    }
    .accoDisplay .textDisplay {
        margin: 1%;
    }
    .accoDisplay h4 {
        font-size: 1rem;
    }
}