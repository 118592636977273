.booking-container {
    display: block;
    padding: 20px;
}

.booking-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    gap: 2%;
    align-items: center;
    padding-left: 10vh;
}

.booking-header1 {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    gap: 2%;
    align-items: center;
}


.Bookingcontainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15%;
    padding-left: 15vh;
}

.left-panel {
    display: flex;
    flex-direction: column;
    gap: 2px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 16px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* .booking-details-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
} */

.booking-details-name {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-left: 2vh;
}

.bookingDetailsImage {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    object-fit: cover;
}

.booking-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    color: #333;
}

.acco-title-span {
    font-size: 14px;
    color: #777;
}

.detail-row {
    display: flex;
    justify-content: space-between;
    padding: 4px;
    font-size: 14px;
    color: #333;
}

.total-price {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
    color: #000;
}

.confirm-pay-button {
    padding: 12px 16px;
    font-size: 16px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.confirm-pay-button:hover {
    background-color: #45a049;
}

/* .goBackButton {
    margin-bottom: 10px;
} */

.right-panel {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    width: 35vw;
}

.booking-details {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}



.login-reserve-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .form-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    color: #333;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
  }
  
  .form-label {
    font-size: 12px;
    color: #666;
  }
  
  .dropdown-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 10px;
    border: 1px solid #ddd;
  }
  
  .country-dropdown {
    appearance: none;
    border: none;
    background: transparent;
    font-size: 14px;
    color: #333;
    flex: 1;
    outline: none;
    cursor: pointer;
  }
  
  .dropdown-icon {
    margin-left: 8px;
    font-size: 12px;
    color: #333;
  }
  
  .phone-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f2f2f2;
    font-size: 14px;
    color: #aaa;
    outline: none;
  }
  

  @media (max-width: 768px) {
    .booking-container {
        padding: 10px;
    }

    .booking-header {
        flex-direction: column;
        text-align: center;
        padding-left: 0;
    }

    .Bookingcontainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-left: 0;
    }

    .left-panel,
    .right-panel {
        width: 100%;
        max-width: none;
        box-shadow: none;
        padding: 10px;
    }

    .booking-details-name {
        flex-direction: column;
        align-items: center;
        padding-left: 0;
    }

    .bookingDetailsImage {
        width: 100px;
        height: 100px;
    }

    .booking-title {
        font-size: 16px;
    }

    .detail-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .confirm-pay-button {
        font-size: 14px;
        padding: 10px;
    }

    .login-reserve-form {
        padding: 10px;
    }

    .form-group {
        gap: 10px;
    }

    .dropdown-wrapper {
        padding: 8px;
    }

    .country-dropdown {
        font-size: 12px;
    }

    .phone-input {
        font-size: 12px;
    }
}

/* For screens smaller than 375px */
@media (max-width: 375px) {
    .booking-container {
        padding: 8px;
    }

    .booking-header {
        flex-direction: column;
        text-align: center;
        padding-left: 0;
    }

    .Bookingcontainer {
        flex-direction: column;
        gap: 15px;
        padding-left: 0;
    }

    .left-panel,
    .right-panel {
        width: 100%;
        padding: 8px;
        box-shadow: none;
    }

    .booking-details-name {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .bookingDetailsImage {
        width: 80px;
        height: 80px;
    }

    .booking-title {
        font-size: 14px;
    }

    .acco-title-span {
        font-size: 12px;
    }

    .detail-row {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }

    .confirm-pay-button {
        font-size: 12px;
        padding: 8px;
    }

    .login-reserve-form {
        padding: 8px;
    }

    .form-title {
        font-size: 14px;
    }

    .form-group {
        gap: 8px;
    }

    .dropdown-wrapper {
        padding: 6px;
    }

    .country-dropdown {
        font-size: 12px;
    }

    .phone-input {
        font-size: 12px;
        padding: 8px;
    }

    .booking-details {
        gap: 10px;
    }

    .total-price {
        font-size: 14px;
        padding-top: 8px;
    }
}
