.container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
}

.dashboards {
    display: grid;
    grid-template-columns: 250px auto;
    gap: 5%;
}

.dashboard {
    display: grid;
    grid-template-columns: 250px auto;
}

.dashboardSections {
    display: flex;
    flex-direction: column;
}

.wijzers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 10px;
    align-items: center;
    min-width: 10vw;
    width: 12.5rem;
    border: 0.5px solid black;
    border-radius: 15px;
    padding: 5px;
    box-sizing: border-box;
    transition: all 0.3s ease;
}

.wijzers img {
    object-fit: cover;
}

.wijzers:hover {
    background-color: var(--primary-color);
    transition: .3s;
    color: white;
}

.wijzers.active {
    background-color: var(--primary-color);
    color: white;
}

.wijzers.active p {
    color: white;
}

.disabled:hover {
    cursor: not-allowed;
    box-shadow: none;
    border: 0.5px solid black;
}

.contentContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.reviewColumn {
    width: 48%;
    height: auto;
    margin: 10px;
    min-width: 155px;
    display: flex;
    flex-direction: column;
}

.reviewBox {
    margin-right: 5px;
    margin-top: 10px;
    padding: 10px;
    height: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    flex-wrap: wrap;
}

.review-tab {
    display: flex;
    flex-direction: column;
    border: 2px;
    border-radius: 15px;
    margin: 5%;
    animation: fade-up 1s;
    padding: 12px;
}

.review-tab:hover {
    box-shadow: 4px 4px #0D9813;
    transition: .3s;
}

.review-date {
    color: #0D9813;
}

.review-delete {
    align-self: end;
    width: 40px;
    height: 40px;
    box-shadow: none;
    padding: 0;
    border: none;
    background-color: white;
}

.review-delete:hover {
    cursor: pointer;
    transform: scale(1.2);
}

.review-alert {
    margin: 10%;
}

.cross {
    width: 30px;
    height: 30px;
}

@keyframes fade-up {
    0% {
        opacity: 0;
        transform: translateY(30px) scale(0.9);
    }
    100% {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}

@media screen and (max-width: 768px) {
    .dashboardSections {
        visibility: hidden;
        width: 0;
        height: 0;
    }
}

@media screen and (max-width: 830px) {
    .dashboards {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dashboard {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .wijzers {
        width: 90%;
    }
}

.button i {
    margin-right: 10px;
    color: #888;
}


.content {
    padding: 20px;
    display: flex;
    justify-content: center;
    gap: 15%;
}

.leftContent {
    height: 360px;
}

.bookingBox {
    height: 60%;
    margin-bottom: 20px;
    overflow: auto;
}

.messageBoxes {
    height: 40%;
    overflow: auto;
}

.viewAllBooking {
    color: #0D9813;
}

.bookingBox,
.messageBox {
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 60%;
}

.bookingBox img {
    width: 20%;
    height: auto;
    border-radius: 15px;
    margin-top: 10px;
}

.messageBox button {
    align-self: flex-end;
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
}

.personalInfoContent {

    flex-direction: column;
    margin-left: 1px;
    width: 100%;
    flex: 1;
    padding: 20px;

}

.accomodation-side {
    flex: 2;
    padding: 20px;
}

.infoBox {
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    width: auto;
}

.infoBox i {
    margin-right: 10px;
    color: #0A760F;
}

.infoBox span {
    margin-right: auto;
    font-size: 1.3rem;
    font-weight: 400;
}

.infoBox p {
    font-size: 1.3rem;
    font-weight: 300;
}

.button.last-button {
    margin-top: 60px;
}

.icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 10px;
    display: inline-block;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 12px;
    background-color: #f9f9f9;
}

.personal-info-header {
    display: flex;
    align-items: center;
    gap: 10px; /* Ruimte tussen de koptekst en de bewerkknop */
}

.edit-icon-background {
    background-color: #0D9813;
    border: 1px solid #0D9813;
    border-radius: 15px;
    padding: 0.4rem;
    width: 2rem;
    height: 2rem;
    text-align: center;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.edit-icon-background:hover {
    background-color: #6CD46F;
}

.guest-edit-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.6rem;
    height: 1.6rem;
    object-fit: contain;
}

.guest-check-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2.5rem;
    height: 2.5rem;
    object-fit: contain;
}

.guest-edit-input {
    font-size: 1.3rem;
    font-weight: 300;
    margin-left: -2%;
}

@media (max-width: 480px) {
    .reviewColumn {
        width: 100%;

        h2.review-header {
            font-size: 1.5em;
        }
    }

    .infoBox {
        padding: 10px;
        font-size: 0.8em;
        width: 90%;
        flex-wrap: wrap;
    }

    .dashboardSections {
        visibility: hidden;
        width: 0;
        height: 0;
    }
}

