.host-dropdown {
    display: none;
}


@media (max-width: 768px) {
    .host-navigation {
        display: none;
    }
    .host-dropdown {
        display: flex;
        flex-direction: column;
        width: 100%;
        .dropdown-section {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 1em;
            padding: 0.5em;
            width: auto;
            select {
                width: 100%;
                text-align: center;
                border-radius: 1em;
                border: none;
                box-shadow: 0 2px 4px gray;
            }
        }
    }
}
