.containerHostDistribution {
    overflow-x: hidden;
}

.host-dist-header {
    display: flex;
    margin-left: 24%;
    flex-direction: row;
    width: 75%;
    padding-bottom: 1%;
    bottom: 100%;
}
/*Begin AddChannel*/
.connectedChannelTitle {
    margin-right: 43%;
}

.exportICalButton {
    background: #052C5A;
    color: white;
    border-radius: 15px;
    padding: 5px 15px;
    width: 15%;
    height: 75%;
    font-size: 16px;
    margin-top: 7%;
}

.exportICalButton:hover {
    opacity: 90%;
    cursor: pointer;
}

.addChannelButton {
    display: flex;
    padding: 5px 15px;
    border-radius: 15px;
    border: 0.50px #000000 solid;
    background: #0fa616;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: white;
    font-size: 16px;
    width: 100%;
    margin-top: 57%;
}

.addChannelButton:hover {
    opacity: 90%;
    cursor: pointer;
}

.addChannelButtonMenu {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
}

.addChannelButtonMenu.visible {
    display: block;
}

.addChannelButtonMenuContent {
    padding: 20px 25px 20px 25px;
    position: absolute;
    background-color: white;
    width: auto;
    min-width: 240px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    top: calc(100% + 5px);
    right: 0;
    border-radius: 10px;
}

.addChannelInputFields {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1%;
    margin-top: 10px;
}

.channels {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1%;
}

.channelAPIKey {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 97%;
    padding: 1%;
}

.addCancelButtonContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 1%;
    flex-wrap: nowrap;
    margin-top: 10%;
}

.addChannelButtonMenuButton.Cancel {
    background: #DADADA;
}

.addChannelButtonMenuButton.Cancel:hover {
    opacity: 90%;
    cursor: pointer;
}

.addChannelButtonMenuButton.Add {
    background: #052C5A;
}

.addChannelButtonMenuButton.Add:hover {
    opacity: 90%;
    cursor: pointer;
}

.addChannelButtonMenuButton {
    padding: 8px 15px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    width: 35%;
    color: white;
    font-size: 16px;
    font-family: Kanit, sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
    margin: 10px 0 0 10px;
}
/*End AddChannel*/
.channelManageButtonContainer {
    display: flex;
    width: 100%;
    padding: 1%;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.channelManageMenuButton {
    display: flex;
    padding: 5px 15px;
    border-radius: 15px;
    border: 0.50px #000000 solid;
    background: #052C5A;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: white;
    font-size: 16px;
    width: 100%;
    margin-top: 10px;
}

.channelManageMenuButton:hover {
    opacity: 90%;
    cursor: pointer;
}

.channelManageMenuButton.enabled {
    background: green;
}

.channelManageMenuButton.disabled {
    background: red;
}

.host-dist-content {
    display: flex;
    justify-content: flex-start;
    gap: 5rem;
    width: 100%;
}

.channelContents {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 77vh;
    padding: 1%;
}

.contentContainer-channel {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: #b9b9b9 1px solid;
    border-radius: 15px;
    height: 79vh;
    overflow-y: hidden;
    overflow-x: hidden;
    top: 7.5rem;
    flex-wrap: wrap;
}

.host-dist-box-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 19.8%;
    border-bottom: #b9b9b9 1px solid;
}

.host-dist-box-container:nth-child(5n) {
    border-bottom: none;
}

.host-dist-box-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 4px 16px 16px 16px;
    align-items: center;
    flex-wrap: wrap;
}

.channelLogo {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
}

.channelFont {
    font-weight: bold;
    color: black;
    flex-basis: 20%;
    text-align: center;
}

.toggle-status-switch {
    flex-shrink: 0;
    position: relative;
    display: inline-block;
    width: 58px;
    height: 32px;
}

.toggle-status-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-status-switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: red;
    transition: 0.4s;
    border-radius: 16px;
}

.toggle-status-switch .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

.toggle-status-switch input:checked + .slider {
    background-color: green;
}

.toggle-status-switch input:checked + .slider:before {
    transform: translateX(26px);
}

.totalListedAccommodations {
    flex-basis: 15%;
    text-align: center;
}

.channelManageButton {
    flex-basis: 15%;
    background: #052C5A;
    color: white;
    border-radius: 15px;
    padding: 5px 15px;
    width: 15%;
    height: 75%;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: auto;
}

.channelManageButton:hover {
    opacity: 90%;
    cursor: pointer;
}

.channelManageMenuContainer {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
}

.channelManageContainer.visible {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
}

.channelManageMenuContent {
    padding: 20px 25px 20px 25px;
    position: absolute;
    background-color: white;
    width: auto;
    min-width: 240px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 5;
    top: calc(100% + 30px);
    left: 49vw;
    border-radius: 10px;
}

.addAccommodationsViewContainer {
    display: none;
}

.addAccommodationsViewContainer.visible {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    right: 20%;
    bottom: 0;
    width: 65vw;
}

.closeAddAccommodationsContent {

}

.closeAddViewButton {
    border-radius: 50%;
    position: absolute;
    right: 1%;
    top: 2.5%;
}

.closeAddViewButton:hover {
    opacity: 70%;
    cursor: pointer;
}

.addAccommodatonsViewContent {
    margin: 20px 0;
    padding: 10px;
    border-radius: 15px;
}

.addAccommodatonsViewContent table {
    width: 100%;
    border-collapse: collapse;
}

.addAccommodatonsViewContent th, .addAccommodatonsViewContent td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
}

.addAccommodatonsViewContent th {
    background-color: #f4f4f4;
    font-weight: bold;
    text-transform: uppercase;
}

.addAccommodatonsViewContent tr:nth-child(even) {
    background-color: #f9f9f9;
}

.addAccommodatonsViewContent tr:hover {
    background-color: #f1f1f1;
}

.addAccommodatonsViewContent td button {
    padding: 6px 12px;
    background-color: #052C5A;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.addAccommodatonsViewContent td button:hover {
    background-color: #0056b3;
}

.removeAccommodationsViewContainer {
    display: none;
}

.removeAccommodationsViewContainer.visible {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    right: 20%;
    bottom: 0;
    width: 65vw;
}

.closeRemoveAccommodationsContent {

}

.closeRemoveViewButton {
    border-radius: 50%;
    position: absolute;
    right: 1%;
    top: 2.5%;
}

.closeRemoveViewButton:hover {
    opacity: 70%;
    cursor: pointer;
}

.removeAccommodationsViewContent {
    margin: 20px 0;
    padding: 10px;
    border-radius: 15px;
}

.removeAccommodationsViewContent table {
    width: 100%;
    border-collapse: collapse;
}

.removeAccommodationsViewContent th, .removeAccommodationsViewContent td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
}

.removeAccommodationsViewContent th {
    background-color: #f4f4f4;
    font-weight: bold;
    text-transform: uppercase;
}

.removeAccommodationsViewContent tr:nth-child(even) {
    background-color: #f9f9f9;
}

.removeAccommodationsViewContent tr:hover {
    background-color: #f1f1f1;
}

.removeAccommodationsViewContent td button {
    padding: 6px 12px;
    background-color: #052C5A;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.removeAccommodationsViewContent td button:hover {
    background-color: #0056b3;
}

/*Begin threeDotsButton*/
.threeDotsButton {
    flex-shrink: 0;
    border: none;
    background: none;
    box-shadow: none;
    color: black;
    text-align: center;
    padding: 12px;
    font-size: 18px;
    border-radius: 10px;
    display: inline-flex;
    font-family: Kanit, sans-serif;
    cursor: pointer;
    width: auto;
    margin-top: 10px;
    margin-bottom: auto;
}

.threeDotsButton:hover {
    cursor: pointer;
    box-shadow: 0 0 5px hsl(0 0% 78%);
}

.threeDotsContainer {
    display: none;
}

.threeDotsContainer.visible {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
}

.threeDotsMenuContent {
    padding: 20px 25px 20px 25px;
    position: absolute;
    background-color: white;
    width: auto;
    max-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 5;
    top: calc(100% + 35px);
    left: 70vw;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.threeDotsButtonMenu {
    background: #052C5A;
    color: white;
    border-radius: 15px;
    padding: 5px 15px;
    width: 100%;
    height: 75%;
    font-size: 16px;
    margin-top: 7%;
}

.threeDotsButtonMenu:hover {
    opacity: 90%;
    cursor: pointer;
}

.threeDotsButtonMenu.delete {
    background: red;
}
/*End threeDotsButton*/
.channelNavigation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: auto;
    padding-top: 2.5%;
}

.channelNavigation button {
    padding: 5px 10px;
    margin: 0 5px;
    border: none;
    background-color: #f4f4f4;
    border-radius: 15px;
}

.channelNavigation button:hover {
    cursor: pointer;
    background-color: #e6e6e6;
}

.channelNavigation button.active {
    background-color: var(--primary-color);
    color: white;
}

.channelNavigation button:hover:disabled {
    opacity: 0.5;
    cursor: default;
}

.channelNavigation button:hover:not(:disabled) {
    background-color: #ddd;
}
