/* ChatWidget.css */
@import "../../../node_modules/slick-carousel/slick/slick.css";
@import "../../../node_modules/slick-carousel/slick/slick-theme.css";


.chatwidget-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.chatwidget-toggle {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
}


@media screen and (max-width: 650px) {
  .chatwidget-toggle{
    position: fixed;
    bottom: 100px;
    right: 20px;
    z-index: 3000;
  cursor: pointer;
  }
}

.chatwidget-resizable {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}


.chatwidget-header {
  background-color: #28a745;
  color: white;
  padding: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatwidget-title {
  font-size: 1.2em;
}

.chatwidget-close {
  background: none;
  border: none;
  font-size: 20px;
  color: white;
  cursor: pointer;
}


.chatwidget-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

.chatwidget-messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px 20px 100px 20px;
  background-color: #f9f9f9;
  max-height: calc(100% - 80px); /* Leave room for the input field */
}


.chatwidget-message {
  margin-bottom: 10px;
}

.chatwidget-message.user {
  text-align: right;
}

.chatwidget-message-content {
  display: inline-block;
  max-width: 60%;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.chatwidget-message-content.user {
  background-color: #28a745;
  color: white;
}

.chatwidget-message-content.ai {
  background-color: #e0e0e0;
}

.chatwidget-message-content.employee {
  background-color: #28a745;
  color: white;
}

.chatwidget-message-content.system {
  background-color: #ffc107;
}

.chatwidget-sender {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Typing indicator */
.chatwidget-typing-indicator {
  font-style: italic;
  color: #888;
}

.chatwidget-dot {
  animation: chatwidget-blink 1s infinite;
  display: inline-block;
  font-size: 1.5em;
}

.chatwidget-dot:nth-child(1) {
  animation-delay: 0s;
}

.chatwidget-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.chatwidget-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes chatwidget-blink {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}


.chatwidget-accommodation-tiles {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.chatwidget-accommodation-tile {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.chatwidget-accommodation-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.chatwidget-accommodation-details {
  padding: 10px;
}

.chatwidget-accommodation-details h3 {
  margin: 0 0 10px;
  font-size: 1.2em;
}

.chatwidget-accommodation-details p {
  margin: 5px 0;
}

.chatwidget-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background: #f9f9f9;
  position: sticky;
  bottom: 0;
}

.chatwidget-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.chatwidget-input button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #28a745;
  color: white;
  cursor: pointer;
  margin-left: 10px;
}

.chatwidget-input button:disabled {
  background-color: #28a745;
  opacity: 0.6;
  cursor: not-allowed;
}

.chatwidget-input input:disabled {
  background-color: #f1f1f1;
}

.chatwidget-input button:hover {
  opacity: 0.9;
}


.chatwidget-decision-box {
  padding: 1px 20px 100px 20px;
  background-color: #f1f1f1;
  border-top: 1px solid #ddd;
  text-align: center;
}

.chatwidget-decision-box p {
  margin-bottom: 10px;
}

.chatwidget-decision-box button {
  background: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
}

.chatwidget-decision-box button:hover {
  opacity: 0.9;
}

.chatwidget-accommodation-tile .chatwidget-slider {
  margin-bottom: 15px;
}

.slick-dots {
  bottom: -25px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #28a745;
}

.slick-dots li.slick-active button:before {
  color: #1d6f34;
}


.slick-prev, .slick-next {
  z-index: 1;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.slick-prev {
  left: 5px;
}

.slick-next {
  right: 5px;
}

.chatwidget-partner-indicator {
  padding: 10px;
  background-color: #f1f1f1;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatwidget-partner-indicator span {
  font-size: 14px;
  color: #333;
}

.chatwidget-switch-to-ai {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: auto; /* Pushes the button to the right */
}

.chatwidget-switch-to-ai:hover {
  background-color: #e04343;
}
