.booked-nights-card {
    width: 320px;
    padding: 20px;
    margin: 20px auto;
    border: 1px solid #ddd;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    text-align: center;
    height: 460px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

}

.booked-nights-card-container{
    margin: 0 auto;
    padding: 20px;
    flex: 1;
}

.booked-nights-card h3 {
    font-size: 20px;
    font-weight: 400;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.booked-nights-card p {
    margin: 20px 10px;
    font-size: 20px;
    font-weight: normal;
    color: #444;
}

.booked-nights-card p1 {
    margin: 20px 10px;
    font-size: 20px;
    font-weight: normal;
    color: #444;
}

.booked-nights-card p2 {
    margin: 20px 10px;
    font-size: 120px;
    font-weight: normal;
    color: #003366;
}



.custom-date-filter input {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: normal;
    color: #555;
}

.custom-date-filter label {
    font-size: 15px;
    font-weight: normal;
    color: #555;
}

.booked-nights-details {
    font-size: 15px;
    font-weight: normal;
    color: #444;
    margin-top: 70px;
}

.booked-nights-card:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
