@import  "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.hostchatbot-toggle-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 3000;
  background-color: #0078D7;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

@media screen and (max-width: 650px) {
  .hostchatbot-toggle-button {
    bottom: 100px;
    right: 20px;
  }
}

.hostchatbot-toggle-button:hover {
  background-color: #005bb5;
}

.hostchatbot-container {
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 2000;
  width: 380px;
  height: 550px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.hostchatbot-container.open {
  opacity: 1;
  visibility: visible;
}

.hostchatbot-header {
  background: linear-gradient(45deg, #0078D7, #00A8E8);
  color: white;
  padding: 12px 20px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hostchatbot-header span {
  font-size: 18px;
  font-weight: bold;
}

.hostchatbot-header .hostchatbot-close-button {
  background: transparent;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.2s ease;
}

.hostchatbot-header .hostchatbot-close-button:hover {
  color: #FFD700;
}

.hostchatbot-window {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.hostchatbot-message {
  margin-bottom: 14px;
  padding: 10px 16px;
  border-radius: 12px;
  max-width: 75%;
  word-wrap: break-word;
  font-size: 14px;
  position: relative;
}

.hostchatbot-message.user {
  align-self: flex-end;
  background-color: #0078D7;
  color: white;
}

.hostchatbot-message.bot {
  align-self: flex-start;
  background-color: #f9f9f9;
  color: #333;
  transition: background-color 0.3s ease;
}

.hostchatbot-message.bot:hover {
  background-color: #e0e0e0;
}

.hostchatbot-option-buttons button {
  background-color: #0078D7;
  color: white;
  border: none;
  padding: 10px 16px;
  margin: 8px 0;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.hostchatbot-option-buttons button:hover {
  background-color: #005bb5;
}

.hostchatbot-suggestions {
  background-color: #f9f9f9;
  padding: 8px 16px;
  border-radius: 8px;
  margin-top: 10px;
}

.hostchatbot-suggestions p {
  font-size: 14px;
  color: #666;
  margin: 0;
  padding: 6px 0;
}

.hostchatbot-input {
  display: flex;
  padding: 12px 16px;
  background-color: #fff;
  border-top: 1px solid #eee;
}

.hostchatbot-input input {
  flex: 1;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 24px;
  margin-right: 10px;
  font-size: 14px;
}

.hostchatbot-input button {
  background-color: #0078D7;
  color: white;
  border: none;
  border-radius: 24px;
  padding: 10px 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hostchatbot-input button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.hostchatbot-go-back-button {
  background-color: #0078D7;
  color: white;
  border: none;
  padding: 10px 16px;
  margin: 12px 0;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease, box-shadow 0.2s ease;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}

.hostchatbot-go-back-button:hover {
  background-color: #005bb5;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.hostchatbot-accommodation-tile {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.hostchatbot-accommodation-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.hostchatbot-accommodation-details {
  margin-top: 10px;
}

.hostchatbot-accommodation-details h3 {
  font-size: 16px;
  margin-bottom: 8px;
}

.hostchatbot-accommodation-details p {
  margin: 4px 0;
  font-size: 14px;
}

.pdf-input-disabled {
  background-color: #f5f5f5;
  color: #9e9e9e;
  cursor: not-allowed;
}


.slick-dots li button:before {
  color: #0078d7;
}

.slick-prev:before,
.slick-next:before {
  color: #0078d7;
}

.faq-layout {
  border: 1px solid #0078d7;
  background-color: #f1f9ff;
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.faq-layout:hover {
  background-color: #e0f2ff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.faq-layout p {
  margin: 10px 0;
  font-size: 15px;
  color: #333;
}

.faq-layout strong {
  font-weight: bold;
  color: #0078d7;
}

.faq-layout .faq-question {
  font-size: 16px;
  font-weight: bold;
  color: #0078d7;
  margin-bottom: 8px;
}

.faq-layout .faq-answer {
  font-size: 14px;
  color: #555;
  line-height: 1.6;
}

/* Style for audio playback buttons */
.play-audio-button {
  background-color: #0078D7;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 6px 10px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 8px;
  transition: background-color 0.3s ease, box-shadow 0.2s ease;
  font-weight: bold;
}

.play-audio-button:hover {
  background-color: #005bb5;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
