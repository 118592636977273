@keyframes bounce {
    0%, 80%, 100% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.5);
    }
  }
  
  .loadingPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .dot {
    background-color: #333;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin: 0 8px;
    animation: bounce 1.5s infinite;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }
  