:root {
    --primary-color: #0D9813;
    --secondary-color: #003366;
}

.page-body {
  width: 93%;
  margin: 0 auto;
}

.accommodation-row {
  display: flex;
  align-items: center;
  margin: 0.5em 0;
}

.image-container {
  margin-right: 12em;
}

.resizable-image {
  width: 25em;
  height: auto;
  display: block;
}

.white-block-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.white-block {
  flex: 1;
  width: 18em;
  height: 18em;
  background-color: white;
  border: 1px solid #ccc;
  margin: 5px;
}

.descriptions-container {
  flex-grow: 1;
}

.manage-link {
  margin-left: 1em;
  margin-right: 3em;
}

.sidebar {
  width: 200px;
  height: 100%;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.sidebar-label {
  margin-top: 10px;
  color: var(--colors-font-tertiary, #5C6670);
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

li a {
  color: var(--colors-font-interactive, #0A760F);
  text-decoration: none;
}

.sidebar-link {
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 10px;
}

.array {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.item-preview {
  width: 360px;
}

.imgacco {
  height: 160px;
  width: 100%;
}

.imgacco img {
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

.toptext {
  display: flex;
  text-indent: 16px;
}

.toptext h3 {
  padding-top: 20px;
}

.selects {
  width: 100%;
  display: flex;
}

.selects p {
  border-radius: 15px;
  border: 1px solid #D9D9D9;
  background: #D9D9D9;
  padding: 8px 12px;
  margin-left: 7px;
  transition: border-color 0.3s ease-in-out;
}

.selects p:hover {
  border-color: #0D9813;
  cursor: pointer;
}

/* Evreything for searchbar en filter */

.assortment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#search-container,
#filter-container {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

#search-container input,
#filter-container input {
  margin-top: 1em;
  background-color: transparent;
  width: 40%;
  border: none;
  border-bottom: 2px solid #0A760F;
  padding: 1em 0.3em;
  outline: none; 
}

.midtext {
  width: 100%;
  display: flex;
}

.bookoverview,
.pricedetails,
.hostinfo {
  position: relative;
  width: 420px;
  height: 300px;
  border-radius: 15px;
  border: 0.5px solid #0D9813;
  padding-left: 20px;
  padding-right: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.bookoverviewbold,
.pricedetailsbold {
  display: flex;
  font-weight: bold;
  gap: 100px;
}

.moreinfo,
.hostinfo h4:hover,
.bottomtext h3:hover {
  color: #0A760F;
  cursor: pointer;
  text-decoration: underline;
}

.moreinfo p:hover,
.hostinfo h4:hover,
.bottomtext h3:hover {
  cursor: pointer;
  text-decoration: underline;
}

.line {
  width: 100%;
  border-bottom: 1px solid black;
  margin-top: 5px;
}

#accoimg {
  width: 470px;
  height: 250px;
  border-radius: 15px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  margin-top: -22px;
}

.bottomtext {
  color: #0A760F;
  width: 1200px;
  display: flex;
  gap: 80px;
  margin-bottom: 100px;
}

.bottomtext h3:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Evreything for the detailpage */

.detailpage {
  padding-left: 60px;
}

.topdetails {
  display: flex;
  padding-top: 20px;
  padding-left: 20px;
  align-items: center;
  gap: 100px;
}

.topdetails button {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: #129518;
  border: none;
}

.topdetails button:hover {
  cursor: pointer;
}

.topdetails ul {
  display: flex;
  gap: 15px;
  display: inline-flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
}

.topdetails li {
  border-radius: 20px;
  border: 0.5px solid #0D9813;
  background: #FFF;
  padding: 5px;
}

#boxheader {
  display: flex;
}

.imagesacco {
  display: flex;
  padding-left: 25px;
  padding-right: 20px;
}

.imagesacco img {
  border-radius: 15px;
}

.bigimg img {
  width: 651px;
  height: 345px;
  flex-shrink: 0;
  margin: 20px;
}

.sideimgtop img,
.sideimgbot img {
  width: 200px;
  height: 165px;
  flex-shrink: 0;
}

.accodetails,
.box1details,
.box2details,
.events,
.reviews,
.reviewbox1 {
  width: 60%;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #0D9813;
  padding-left: 20px;
  padding-right: 20px;
  margin: 10px;
}

.events img,
.accodetails img {
  width: 190px;
  height: 200px;
  border-radius: 15px;
}

.accodetails h2,
#ondertitel,
.events h2,
.reviews h2 {
  padding-left: 20px;
}

.accodetails h4,
.events h4,
.reviews h4 {
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  color: #0A760F;
}

.accodetails h4:hover,
.events h4:hover,
.reviews h4:hover {
  text-decoration: underline;
  cursor: pointer;
}

#reviewscore1,
#reviewscore2 {
  border-radius: 15px;
  border-width: 40%;
  display: flex;
  gap: 70%;
  margin-bottom: 10px;
}

* {
  font-family: Kanit, sans-serif;
}